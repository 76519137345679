import { FC, useState, useRef, ChangeEvent, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import errorIcon from 'assets/vectors/error-icon.svg';
import calendarIcon from 'assets/vectors/calendar-icon.svg';
import showPasswordIcon from 'assets/vectors/password-show.svg';
import hidePasswordIcon from 'assets/vectors/password-hide.svg';

import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import DatePicker from 'components/UI/DatePicker';
import { regExp } from 'utils/constants';

import {
  Container,
  Input,
  InputContainer,
  InputLabel,
  InputButton,
  ErrorIcon,
  ErrorMessage
} from './styled';

type CustomIcon = {
  src: any;
  alt: string;
  onClick: () => void;
};

type Props = {
  description: string;
  placeholder?: string;
  type?: string;
  register?: any;
  defaultValue?: string;
  disabled?: boolean;
  tight?: boolean;
  error?: any;
  icon?: CustomIcon;
  maxDate?: Date;
  uppercase?: boolean;
  optional?: boolean;
  setDate?: (date: Date) => void;
  ignoreNonAlphanumeric?: boolean;
};

const InputField: FC<Props> = ({
  description,
  register,
  type,
  defaultValue,
  disabled = false,
  optional = false,
  tight = false,
  placeholder = ' ',
  error,
  icon,
  maxDate,
  uppercase,
  setDate,
  ignoreNonAlphanumeric = false
}) => {
  const intl = useIntl();

  // Refs
  const calendarRef = useRef<HTMLDivElement>(null);
  const calendarButtonRef = useRef<HTMLButtonElement>(null);

  // State
  const [focus, setFocus] = useState<boolean>(false);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useDetectOutsideClick(
    [calendarRef, calendarButtonRef],
    false
  );

  // Handle focus / blur
  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  // Handle toggle calendar
  const onCalToggle = useCallback(
    () => setCalendarOpen(!calendarOpen),
    [setCalendarOpen, calendarOpen]
  );

  // Uppercase input
  const onInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (uppercase) {
        event.target.value = event.target.value.toLocaleUpperCase();
      }

      if (ignoreNonAlphanumeric) {
        event.target.value = event.target.value.replace(
          regExp.nonAlphanumeric,
          ''
        );
      }
    },
    [uppercase, ignoreNonAlphanumeric]
  );

  // Render input button
  const inputButton = useMemo(() => {
    if (error) {
      return <ErrorIcon src={errorIcon} alt="Error" />;
    }
    if (icon) {
      return (
        <InputButton type="button" onClick={() => icon.onClick()}>
          <img src={icon.src} alt={icon.alt} />
        </InputButton>
      );
    }
    if (type === 'password') {
      return (
        <InputButton
          type="button"
          onClick={() => setPasswordShow(!passwordShow)}
        >
          <img
            src={!passwordShow ? showPasswordIcon : hidePasswordIcon}
            alt="show/hide password"
          />
        </InputButton>
      );
    }
    if (type === 'date') {
      return (
        <InputButton
          type="button"
          ref={calendarButtonRef}
          onClick={onCalToggle}
        >
          <img src={calendarIcon} alt="calendar" />
        </InputButton>
      );
    }
    return null;
  }, [onCalToggle, error, icon, type, passwordShow]);

  // Default date
  const defaultDate = useMemo(() => {
    if (type === 'date' && defaultValue) {
      return new Date(defaultValue);
    }
  }, [type, defaultValue]);

  return (
    <Container>
      <InputContainer
        $isFocus={focus}
        $isTight={tight}
        $isError={error}
        disabled={disabled}
      >
        <Input
          {...register}
          defaultValue={defaultValue}
          placeholder={placeholder}
          type={passwordShow ? 'text' : type}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          $isTight={tight}
          onClick={type === 'date' ? onCalToggle : undefined}
        />
        <InputLabel
          $isFocus={focus}
          $isTight={tight}
          $isError={error}
          disabled={disabled}
        >
          <FormattedMessage id={description} />
          {optional && ` (${intl.formatMessage({ id: 'misc.optional' })})`}
        </InputLabel>
        {inputButton}
      </InputContainer>
      <DatePicker
        isOpen={calendarOpen}
        refValue={calendarRef}
        defaultValue={defaultDate}
        onClose={onCalToggle}
        onChange={setDate}
        maxDate={maxDate}
      />
      <ErrorMessage>
        {error && <FormattedMessage id={`${error.message}`} />}
      </ErrorMessage>
    </Container>
  );
};
export default InputField;
