import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  fetchServicePlans,
  resetServicePlanState,
  servicePlanSelector
} from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as routes from 'router/Routes';

import ServicePlanViewer from 'components/serviceplans/ServicePlanViewer';
import { SelectField, OptionType } from 'components/UI/SelectField';
import BackButton from 'components/UI/BackButton';
import Heading from 'components/UI/Heading';

import { HeaderGrid, HeaderLeftCol, HeaderRightCol } from './styled';

const ProductServicePlansPage: FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { iprId } = useParams();
  const { data, isLoading } = useAppSelector(servicePlanSelector);

  // State
  const [humanResource, setHumanResource] = useState<string>('');

  // Select human resource
  const onSelectHumanResource = useCallback(
    (option: OptionType) => setHumanResource(option.value),
    []
  );

  // Fetch service plan
  useEffect(() => {
    if (iprId) {
      dispatch(fetchServicePlans({ iprId }));
    }
    return () => {
      dispatch(resetServicePlanState());
    };
  }, [dispatch, iprId]);

  // Options
  const options: OptionType[] = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'service_plan.select_resource_operator_technician'
        }),
        value: 'OPERATOR_TECHNICIAN',
        key: 'operator_technician'
      },
      {
        label: intl.formatMessage({
          id: 'service_plan.select_resource_operator'
        }),
        value: 'OPERATOR',
        key: 'operator'
      },
      {
        label: intl.formatMessage({
          id: 'service_plan.select_resource_technician'
        }),
        value: 'TECHNICIAN',
        key: 'technician'
      }
    ],
    [intl]
  );

  return (
    <ServicePlanViewer
      data={data}
      isLoading={isLoading}
      humanResource={humanResource}
    >
      <HeaderGrid>
        <HeaderLeftCol>
          <BackButton navUrl={routes.productDetailsLink(iprId)} />
          <Heading>{data?.attributes.productName}</Heading>
        </HeaderLeftCol>
        <HeaderRightCol>
          <SelectField
            name="humanResource"
            onSelect={onSelectHumanResource}
            placeholder={intl.formatMessage({
              id: 'service_plan.select_resource_all'
            })}
            label={intl.formatMessage({
              id: 'service_plan.select_resource_label'
            })}
            options={options}
            noBorder
          />
        </HeaderRightCol>
      </HeaderGrid>
    </ServicePlanViewer>
  );
};

export default ProductServicePlansPage;
