import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { batchJobListSelector, fetchBatchJobList } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Card from 'components/UI/Card';
import Heading from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import BatchJobTable from 'components/batchJobs/BatchJobTable';
import { CreateBatchJobModal } from 'components/batchJobs/CreateBatchJobModal';
import { IconType } from 'components/UI/Icon';

import { HeaderGrid } from './styled';

const BatchJobsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector(batchJobListSelector);

  // State
  const [createJobModal, setCreateJobModal] = useState(false);

  // Actions
  const onToggleCreateModal = useCallback(
    () => setCreateJobModal(!createJobModal),
    [createJobModal]
  );

  const onFetchBatchJobs = useCallback(() => {
    dispatch(fetchBatchJobList());
  }, [dispatch]);

  // Fetch batch jobs
  useEffect(() => {
    onFetchBatchJobs();
  }, [onFetchBatchJobs]);

  // Render results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    if (!data?.length) {
      return (
        <EmptyState icon={IconType.Tune} padding>
          <FormattedMessage id="batch_jobs.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <BatchJobTable data={data} />
      </Card>
    );
  }, [data, isLoading]);

  return (
    <Fragment>
      <HeaderGrid>
        <Heading>
          <FormattedMessage id="batch_jobs.title" />
        </Heading>
        <Button onClick={onToggleCreateModal}>
          <FormattedMessage id="batch_jobs.btn_create" />
        </Button>
      </HeaderGrid>
      {results}
      <CreateBatchJobModal
        open={createJobModal}
        onCreate={onFetchBatchJobs}
        onClose={onToggleCreateModal}
      />
    </Fragment>
  );
};

export default BatchJobsPage;
