import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import * as routes from 'router/Routes';
import {
  CustomerAttributes,
  Pagination,
  ProductRegistration,
  Registration
} from 'models';
import { getProductText } from 'utils/product';
import { isEmptyArray } from 'utils/array';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import EditProductModal, {
  DeleteRegistrationsProps
} from 'components/products/EditProductModal';
import RegisterProductsModal from 'components/products/RegisterProductsModal';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import Tag from 'components/UI/Tag';
import Button from 'components/UI/Button';

import {
  LoadMoreGrid,
  LoadMoreText,
  RegisterButtonInner,
  Registrations
} from './styled';
import EmptyState from 'components/UI/EmptyState';

const headerCells: HeaderCell[] = [
  {
    sortable: false,
    intl: ''
  },
  {
    sortable: true,
    intl: 'customers.product_table_title'
  },
  {
    sortable: true,
    intl: 'customers.product_table_pnc'
  },
  {
    sortable: true,
    intl: 'customers.product_table_serial'
  },
  {
    sortable: true,
    intl: 'customers.product_table_relationships'
  },
  {
    sortable: false,
    intl: 'customers.product_table_remove'
  }
];

type RemoveModal = {
  open: boolean;
  productRegistration: ProductRegistration | null;
};

type Props = {
  customer: CustomerAttributes;
  productRegistrations: ProductRegistration[];
  onDeleteRegistrations: (props: DeleteRegistrationsProps) => void;
  updateData: () => void;
  onLoadMore?: () => void;
  pagination?: Pagination;
  isLoading: boolean;
  canEdit: boolean;
};

const CustomerProductCard: FC<Props> = ({
  productRegistrations,
  customer,
  onDeleteRegistrations,
  onLoadMore,
  updateData,
  pagination,
  isLoading,
  canEdit
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // State
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<RemoveModal>({
    open: false,
    productRegistration: null
  });

  // Callbacks
  const onClick = useCallback(
    (id: string) => {
      navigate(routes.productDetailsLink(id), {
        state: { from: pathname }
      });
    },
    [navigate, pathname]
  );

  // Actions
  const onOpenRegisterModal = useCallback(() => setRegisterModal(true), []);
  const onCloseRegisterModal = useCallback(() => setRegisterModal(false), []);
  const onCloseRemoveModal = useCallback(() => {
    setRemoveModal({ open: false, productRegistration: null });
  }, []);

  // Render relation
  const renderRegistrations = useCallback((registrations: Registration[]) => {
    if (isEmptyArray(registrations)) {
      return '-';
    }
    return (
      <Registrations>
        {registrations.map((item) => (
          <Tag key={item.id}>{item.relationshipType}</Tag>
        ))}
      </Registrations>
    );
  }, []);

  // Load more
  const loadMoreButton = useMemo(() => {
    if (pagination?.isLoading) {
      return <Loader center />;
    }
    if (pagination?.cursor) {
      return (
        <Button
          onClick={onLoadMore}
          backgroundColor="surface"
          color="onSurface"
        >
          <FormattedMessage id="button.load_more" />
        </Button>
      );
    }
    return null;
  }, [pagination, onLoadMore]);

  // Render table
  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    if (isEmptyArray(productRegistrations)) {
      return (
        <EmptyState icon={IconType.Product}>
          <FormattedMessage id="customer_details.products_empty" />
        </EmptyState>
      );
    }

    return (
      <div>
        <SortableTable
          headerCells={headerCells}
          bodyRows={productRegistrations.map((productRegistration) => {
            const { product, registrations } = productRegistration;
            const { imageUrl, serialNumber, pnc, iprId } = product;

            return {
              imageUrl,
              product: getProductText(product),
              pnc,
              serialNumber,
              relations: renderRegistrations(registrations),
              editButton: canEdit ? (
                <IconButton
                  onClick={() =>
                    setRemoveModal({ open: true, productRegistration })
                  }
                >
                  <Icon type={IconType.Remove} />
                </IconButton>
              ) : (
                '–'
              ),
              link: iprId
            };
          })}
          emptyTextId="customer_details.products_empty"
          onClick={onClick}
        />
        <LoadMoreGrid>
          <LoadMoreText>
            <FormattedMessage
              id="pagination.items"
              values={{ value: productRegistrations.length }}
            />
          </LoadMoreText>
          {loadMoreButton}
        </LoadMoreGrid>
      </div>
    );
  }, [
    productRegistrations,
    isLoading,
    canEdit,
    loadMoreButton,
    onClick,
    renderRegistrations
  ]);

  return (
    <Card>
      <CardHeader titleIntl="customer_details.products_title">
        {canEdit && (
          <Button
            onClick={onOpenRegisterModal}
            color="onSecondary"
            backgroundColor="secondary"
            size="small"
          >
            <RegisterButtonInner>
              <Icon type={IconType.Add} themeType="grey6" />
              <FormattedMessage id="modal.register_products_title" />
            </RegisterButtonInner>
          </Button>
        )}
      </CardHeader>
      {table}
      <EditProductModal
        customer={customer}
        productRegistration={removeModal.productRegistration}
        open={removeModal.open}
        onDeleteRegistrations={onDeleteRegistrations}
        onClose={onCloseRemoveModal}
      />
      <RegisterProductsModal
        open={registerModal}
        onUpdate={updateData}
        onClose={onCloseRegisterModal}
        customerId={customer.ceid}
      />
    </Card>
  );
};

export default CustomerProductCard;
