import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  ServiceContractFilter,
  ServiceContractResponse,
  ServiceContractStatus,
  ServiceContractStatusUpdate,
  ServiceContractSuccess,
  ServiceContractExportResponse,
  APIError,
  ServiceContractState
} from 'models';
import * as services from 'services';
import { showErrorToast } from 'utils/toast';
import type { RootState } from 'store/store';

export const defaultState: ServiceContractState = {
  list: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null,
    filters: {
      active: false,
      data: null
    },
    exports: {
      data: null,
      errors: [],
      isLoading: false
    }
  },
  details: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  status: {
    data: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  sendMail: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  },
  move: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

// Export slice
export const serviceContractSlice = createSlice({
  name: 'serviceContract',
  initialState: defaultState,
  reducers: {
    getServiceContractListInit: (state) => {
      state.list.isLoading = true;
      state.list.isError = false;
      state.list.error = null;
    },
    getServiceContractListSuccess: (
      state,
      action: PayloadAction<ServiceContractSuccess>
    ) => {
      state.list.isSuccess = true;
      state.list.isLoading = false;
      state.list.data = action.payload.response;
      state.list.filters.data = action.payload.filters;
    },
    getServiceContractListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.isError = true;
      state.list.error = action.payload;
      state.list.filters.data = null;
    },
    resetServiceContractList: (state) => {
      state.list = defaultState.list;
    },
    setServiceContractFilters: (state, action: PayloadAction<boolean>) => {
      state.list.filters.active = action.payload;
    },
    resetExportServiceContracts: (state) => {
      state.list.exports.data = null;
    },
    exportServiceContractsInit: (state) => {
      state.list.exports.isLoading = true;
      state.list.exports.data = null;
      state.list.exports.errors = [];
    },
    exportServiceContractsSuccess: (
      state,
      action: PayloadAction<ServiceContractExportResponse>
    ) => {
      state.list.exports.isLoading = false;
      state.list.exports.data = action.payload.data;
      state.list.exports.errors = action.payload.errors;
    },
    exportServiceContractsFailure: (state, action: PayloadAction<APIError>) => {
      state.list.exports.data = null;
      state.list.exports.isLoading = false;
      state.list.exports.errors = [action.payload];
    },
    getServiceContractInit: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getServiceContractSuccess: (
      state,
      action: PayloadAction<ServiceContractResponse>
    ) => {
      state.details.isSuccess = true;
      state.details.isLoading = false;
      state.details.error = null;
      state.details.data = action.payload;
    },
    getServiceContractFailure: (state, action: PayloadAction<APIError>) => {
      state.details.isLoading = false;
      state.details.isError = true;
      state.details.error = action.payload;
    },
    updateServiceContractStatusInit: (state) => {
      state.status.isSuccess = false;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = null;
    },
    updateServiceContractStatusSuccess: (
      state,
      action: PayloadAction<ServiceContractStatus>
    ) => {
      const { data } = state.details;
      if (data) {
        data.attributes.serviceContract.status = action.payload;
      }
      state.status.data = action.payload;
      state.status.isSuccess = true;
      state.status.isLoading = false;
    },
    updateServiceContractStatusFailure: (
      state,
      action: PayloadAction<APIError>
    ) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    sendServiceContractMailInit: (state) => {
      state.sendMail.isSuccess = false;
      state.sendMail.isLoading = true;
      state.sendMail.isError = false;
      state.sendMail.error = null;
    },
    sendServiceContractMailSuccess: (state) => {
      state.sendMail.isSuccess = true;
      state.sendMail.isLoading = false;
    },
    sendServiceContractMailFailure: (
      state,
      action: PayloadAction<APIError>
    ) => {
      state.sendMail.isLoading = false;
      state.sendMail.isError = true;
      state.sendMail.error = action.payload;
    },
    moveServiceContractInit: (state) => {
      state.move.isSuccess = false;
      state.move.isLoading = true;
      state.move.isError = false;
      state.move.error = null;
    },
    moveServiceContractSuccess: (state) => {
      state.move.isSuccess = true;
      state.move.isLoading = false;
    },
    moveServiceContractFailure: (state, action: PayloadAction<APIError>) => {
      state.move.isLoading = false;
      state.move.isError = true;
      state.move.error = action.payload;
    },
    resetServiceContractDetails: (state) => {
      state.details = defaultState.details;
    },
    resetUpdateServiceContractStatus: (state) => {
      state.status = defaultState.status;
    },
    resetSendServiceContractMail: (state) => {
      state.sendMail = defaultState.sendMail;
    },
    resetMoveServiceContract: (state) => {
      state.move = defaultState.move;
    }
  }
});

// Export selectors
export const serviceContractListSelector = (state: RootState) =>
  state.serviceContract.list;
export const serviceContractDetailsSelector = (state: RootState) =>
  state.serviceContract.details;
export const serviceContractStatusUpdateSelector = (state: RootState) =>
  state.serviceContract.status;
export const serviceContractSendMailSelector = (state: RootState) =>
  state.serviceContract.sendMail;
export const serviceContractMoveSelector = (state: RootState) =>
  state.serviceContract.move;

// Export actions
export const {
  getServiceContractListInit,
  getServiceContractListSuccess,
  getServiceContractListFailure,
  setServiceContractFilters,
  resetExportServiceContracts,
  exportServiceContractsInit,
  exportServiceContractsSuccess,
  exportServiceContractsFailure,
  getServiceContractInit,
  getServiceContractSuccess,
  getServiceContractFailure,
  updateServiceContractStatusInit,
  updateServiceContractStatusSuccess,
  updateServiceContractStatusFailure,
  sendServiceContractMailInit,
  sendServiceContractMailSuccess,
  sendServiceContractMailFailure,
  moveServiceContractInit,
  moveServiceContractSuccess,
  moveServiceContractFailure,
  resetServiceContractList,
  resetUpdateServiceContractStatus,
  resetServiceContractDetails,
  resetSendServiceContractMail,
  resetMoveServiceContract
} = serviceContractSlice.actions;

// Export reducer
export const serviceContractReducer = serviceContractSlice.reducer;

// Export thunk
export const fetchServiceContracts =
  (filters: ServiceContractFilter[], limit: number, offset: number) =>
  async (dispatch: Dispatch) => {
    dispatch(getServiceContractListInit());
    try {
      const response = await services.findServiceContractsByFilters(
        filters,
        limit,
        offset
      );
      dispatch(
        getServiceContractListSuccess({
          response,
          filters: response.length ? filters : null
        })
      );
    } catch (e: any) {
      dispatch(getServiceContractListFailure(e));
    }
  };

export const exportServiceContracts =
  (filters: ServiceContractFilter[], limit: number, offset: number) =>
  async (dispatch: Dispatch) => {
    dispatch(exportServiceContractsInit());
    try {
      const response = await services.getCSVExport(filters, limit, offset);
      dispatch(exportServiceContractsSuccess(response));
    } catch (e: any) {
      dispatch(exportServiceContractsFailure(e));
    }
  };

export const fetchServiceContract =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(getServiceContractInit());
    try {
      const response = await services.findServiceContractById(id);
      dispatch(getServiceContractSuccess(response));
    } catch (e: any) {
      dispatch(getServiceContractFailure(e));
      showErrorToast(e.detail);
    }
  };

export const updateServiceContractStatus =
  (id: string, update: ServiceContractStatusUpdate) =>
  async (dispatch: Dispatch) => {
    dispatch(updateServiceContractStatusInit());
    try {
      const result = await services.updateServiceContractStatusById(id, update);
      dispatch(updateServiceContractStatusSuccess(result));
    } catch (e: any) {
      dispatch(updateServiceContractStatusFailure(e));
      showErrorToast(e.detail);
    }
  };

export const sendServiceContractMail =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(sendServiceContractMailInit());
    try {
      await services.sendServiceContractEmail(id);
      dispatch(sendServiceContractMailSuccess());
    } catch (e: any) {
      dispatch(sendServiceContractMailFailure(e));
      showErrorToast(e.detail);
    }
  };

export const moveServiceContract =
  (id: string, pnc: string, serialNumber: string) =>
  async (dispatch: Dispatch) => {
    dispatch(moveServiceContractInit());
    try {
      await services.moveServiceContract(id, pnc, serialNumber);
      dispatch(moveServiceContractSuccess());
    } catch (e: any) {
      dispatch(moveServiceContractFailure(e));
      showErrorToast(e.detail);
    }
  };
