import { BatchJobStatus, StatusColor } from 'models';

export function getBatchJobStatusColors(status?: BatchJobStatus): StatusColor {
  switch (status) {
    case 'COMPLETED':
      return {
        background: 'success',
        color: 'onPrimary'
      };
    case 'CREATED':
    case 'WAITING':
    case 'PROCESSING':
    case 'CREATING':
    case 'DELETING':
      return {
        background: 'info',
        color: 'onPrimary'
      };
    case 'COMPLETED_WITH_ERRORS':
    case 'CANCELED':
      return {
        background: 'error',
        color: 'onPrimary'
      };
    default:
      return {
        background: 'grey3',
        color: 'grey6'
      };
  }
}
