import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { useAppSelector } from 'hooks/redux';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { ServiceContractFilter, ServiceContractFilterAttribute } from 'models';
import { metaSelector } from 'store';
import { ButtonGrid, ThreeColumnGrid } from 'styles';
import { formatDate } from 'utils/date';
import { createFilters, validateField } from 'utils/filtering';
import { getSalesCompanyCodeOptions } from 'utils/meta';
import { getPaymentProviderLabel } from 'utils/serviceContracts';

import Button from 'components/UI/Button';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';
import { SelectField, OptionType } from 'components/UI/SelectField';

type Props = {
  onFilter: (value: ServiceContractFilter[]) => void;
  onClear: () => void;
};

const ServiceContractFilters: FC<Props> = ({ onFilter, onClear }) => {
  const intl = useIntl();
  const meta = useAppSelector(metaSelector);
  const countryDisplayNames = useDisplayNames('region');
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  // Watch
  const [
    watchType,
    watchStartDate,
    watchEndDate,
    watchStatus,
    watchSellerId,
    watchPaymentProvider,
    watchEmail,
    watchCustomerType
  ] = watch([
    ServiceContractFilterAttribute.Type,
    ServiceContractFilterAttribute.StartDate,
    ServiceContractFilterAttribute.EndDate,
    ServiceContractFilterAttribute.Status,
    ServiceContractFilterAttribute.SellerId,
    ServiceContractFilterAttribute.PaymentProvider,
    ServiceContractFilterAttribute.Email,
    ServiceContractFilterAttribute.CustomerType
  ]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const filterValues = {
        ...values,
        sellerId: values.sellerId ? `PREFIX:${values.sellerId}` : '',
        startDate: values.startDate
          ? `GTE:${new Date(values.startDate).valueOf()}`
          : '',
        endDate: values.endDate
          ? `LTE:${new Date(values.endDate).valueOf()}`
          : ''
      };
      const filters = createFilters<ServiceContractFilter>(filterValues);
      if (filters.length) {
        onFilter(filters);
      }
    },
    [onFilter]
  );

  const onReset = () => {
    onClear();
    reset({
      [ServiceContractFilterAttribute.Type]: '',
      [ServiceContractFilterAttribute.StartDate]: '',
      [ServiceContractFilterAttribute.EndDate]: '',
      [ServiceContractFilterAttribute.Status]: '',
      [ServiceContractFilterAttribute.SellerId]: '',
      [ServiceContractFilterAttribute.PaymentProvider]: '',
      [ServiceContractFilterAttribute.Email]: '',
      [ServiceContractFilterAttribute.CustomerType]: ''
    });
  };

  // Set date
  const setDate = useCallback(
    (name: ServiceContractFilterAttribute) => (date: Date) => {
      setValue(name, formatDate(date.getTime(), 'yyyy-MM-dd'), {
        shouldDirty: true
      });
    },
    [setValue]
  );

  // Select option
  const onSelect = useCallback(
    (name: ServiceContractFilterAttribute) => (option: OptionType) =>
      setValue(name, option.value, { shouldDirty: true }),
    [setValue]
  );

  // Parse label
  const parseOptionLabel = (langId: string, listName: string): string => {
    const name = listName.split(' ').join('_').toLowerCase();
    const id = `${langId}_${name}`;
    return `${intl.formatMessage({ id })}`;
  };

  // Salescompany Countries
  const sccOptions: OptionType[] = useMemo(() => {
    return getSalesCompanyCodeOptions(
      meta.data.salesCompanyCodes.read,
      countryDisplayNames
    );
  }, [countryDisplayNames, meta]);

  // Status
  const contractStatusOptions: OptionType[] = meta.data.contractStatuses.map(
    ({ name }) => ({
      key: name,
      label: parseOptionLabel('contracts.contract_status', name),
      value: name
    })
  );

  // Types
  const contractTypeOptions: OptionType[] = meta.data.contractTypes.map(
    ({ name }) => ({
      key: name,
      label: parseOptionLabel('contracts.contract_type', name),
      value: name
    })
  );

  // Payment providers
  const paymentProviderOptions: OptionType[] = meta.data.paymentProviders.map(
    ({ name }) => ({
      key: name,
      label: getPaymentProviderLabel(name),
      value: name
    })
  );

  // Customer types
  const customerTypeOptions: OptionType[] = meta.data.customerTypes.map(
    ({ name }) => ({
      key: name,
      label: parseOptionLabel('customers.customer_type', name),
      value: name
    })
  );

  if (meta.isLoading) {
    return <Loader center padding />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ThreeColumnGrid>
        <SelectField
          name={ServiceContractFilterAttribute.Type}
          register={register(ServiceContractFilterAttribute.Type)}
          onSelect={onSelect(ServiceContractFilterAttribute.Type)}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'contracts.input_type' })}
          defaultValue={watchType}
          options={contractTypeOptions}
          error={errors[ServiceContractFilterAttribute.Type]}
        />
        <InputField
          type="date"
          description="contracts.input_start_date"
          register={register(ServiceContractFilterAttribute.StartDate)}
          setDate={setDate(ServiceContractFilterAttribute.StartDate)}
          defaultValue={watchStartDate}
          error={errors[ServiceContractFilterAttribute.StartDate]}
        />
        <InputField
          type="date"
          description="contracts.input_end_date"
          register={register(ServiceContractFilterAttribute.EndDate)}
          setDate={setDate(ServiceContractFilterAttribute.EndDate)}
          defaultValue={watchEndDate}
          error={errors[ServiceContractFilterAttribute.EndDate]}
        />
        <SelectField
          name={ServiceContractFilterAttribute.PaymentProvider}
          register={register(ServiceContractFilterAttribute.PaymentProvider)}
          onSelect={onSelect(ServiceContractFilterAttribute.PaymentProvider)}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({
            id: 'contracts.input_payment_provider'
          })}
          options={paymentProviderOptions}
          defaultValue={watchPaymentProvider}
          error={errors[ServiceContractFilterAttribute.PaymentProvider]}
        />
        <SelectField
          name={ServiceContractFilterAttribute.Status}
          register={register(ServiceContractFilterAttribute.Status)}
          onSelect={onSelect(ServiceContractFilterAttribute.Status)}
          defaultValue={watchStatus}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({ id: 'contracts.input_status' })}
          options={contractStatusOptions}
          error={errors[ServiceContractFilterAttribute.Status]}
        />
        <SelectField
          name={ServiceContractFilterAttribute.SellerId}
          register={register(ServiceContractFilterAttribute.SellerId)}
          onSelect={onSelect(ServiceContractFilterAttribute.SellerId)}
          defaultValue={watchSellerId}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({
            id: 'contracts.input_salescompany'
          })}
          options={sccOptions}
          enableSearch
          error={errors[ServiceContractFilterAttribute.SellerId]}
        />
        <InputField
          type="text"
          description="contracts.input_customer_email"
          register={register(ServiceContractFilterAttribute.Email, {
            required: validateField(
              !!watchCustomerType,
              intl.formatMessage({
                id: 'contracts.validation_customer_email_and_customer_type'
              })
            )
          })}
          error={errors[ServiceContractFilterAttribute.Email]}
        />
        <SelectField
          name={ServiceContractFilterAttribute.CustomerType}
          register={register(ServiceContractFilterAttribute.CustomerType, {
            required: validateField(
              !!watchEmail,
              intl.formatMessage({
                id: 'contracts.validation_customer_email_and_customer_type'
              })
            )
          })}
          onSelect={onSelect(ServiceContractFilterAttribute.CustomerType)}
          placeholder={intl.formatMessage({ id: 'select.placeholder' })}
          label={intl.formatMessage({
            id: 'contracts.input_customer_type'
          })}
          defaultValue={watchCustomerType}
          options={customerTypeOptions}
          error={errors[ServiceContractFilterAttribute.CustomerType]}
        />
      </ThreeColumnGrid>
      <ButtonGrid>
        <Button
          onClick={onReset}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="search.clear" />
        </Button>
        <Button submit>
          <FormattedMessage id="search.advanced_btn_submit" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default ServiceContractFilters;
