import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  findCustomersByFilter,
  customerListSelector,
  setCustomerListFilters,
  setCustomerTypeFilter,
  metaSelector
} from 'store';
import { CustomerFilter, CustomerFilterAttribute, CustomerType } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useParseOptionLabel } from 'hooks/useParseOptionLabel';
import { createFilters } from 'utils/filtering';

import Card from 'components/UI/Card';
import Button from 'components/UI/Button';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';
import { SelectField, OptionType } from 'components/UI/SelectField';
import SearchField from 'components/UI/SearchField';
import FoldableCard from 'components/UI/FoldableCard';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import CreateCustomerModal from 'components/customers/CreateProCustomerModal';
import CustomerFilters from 'components/customers/CustomerFilters';
import CustomerTable from 'components/customers/CustomerTable';

import { HeaderGrid, SearchGrid } from './styled';

const CustomerSearchPage: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const parseOptionLabel = useParseOptionLabel();
  const meta = useAppSelector(metaSelector);
  const { data, isLoading, filters } = useAppSelector(customerListSelector);

  // State
  const [createCustomerModal, setCreateCustomerModal] =
    useState<boolean>(false);

  // Toggle
  const toggleCreateCustomerModal = useCallback(
    () => setCreateCustomerModal(!createCustomerModal),
    [createCustomerModal]
  );

  // Toggle filters
  const toggleFilters = useCallback(() => {
    dispatch(setCustomerListFilters(!filters.active));
  }, [dispatch, filters.active]);

  // Search
  const onSearch = useCallback(
    (email: string) => {
      const searchFilters = createFilters<CustomerFilter>({
        [CustomerFilterAttribute.Email]: email,
        [CustomerFilterAttribute.CustomerType]: filters.customerType
      });
      dispatch(findCustomersByFilter(searchFilters));
    },
    [dispatch, filters]
  );

  // Select type
  const onSelectCustomerType = useCallback(
    (option: OptionType) => {
      dispatch(setCustomerTypeFilter(option.value as CustomerType));
    },
    [dispatch]
  );

  // Filter search
  const onFilter = useCallback(
    (filters: CustomerFilter[]) => {
      dispatch(findCustomersByFilter(filters));
    },
    [dispatch]
  );

  // Customer types
  const customerTypeOptions: OptionType[] = meta.data.customerTypes.map(
    (type) => ({
      key: type.name,
      label: parseOptionLabel('customers.customer_type', type.name),
      value: type.name
    })
  );

  // Results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    if (!data) {
      return null;
    }

    if (!data.length) {
      return (
        <EmptyState icon={IconType.Customer} padding>
          <FormattedMessage id="customer_details.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="products.table_title" />
        </Heading>
        <CustomerTable customers={data} />
      </Card>
    );
  }, [isLoading, data]);

  if (meta.isLoading) {
    return <Loader center padding />;
  }

  return (
    <Fragment>
      <HeaderGrid>
        <Heading>
          <FormattedMessage id="customers.title" />
        </Heading>
        <Button onClick={toggleCreateCustomerModal}>
          <FormattedMessage id="customers.btn_create" />
        </Button>
      </HeaderGrid>
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.title" />
        </Heading>
        <SearchField
          onSearch={onSearch}
          placeholder={intl.formatMessage({
            id: 'customers.search_placeholder'
          })}
          buttons={
            <Button
              onClick={toggleFilters}
              backgroundColor="surface"
              color={filters.active ? 'primaryDark' : 'onSurface'}
            >
              <FormattedMessage id="search.advanced_btn" />
            </Button>
          }
        >
          <SearchGrid>
            <SelectField
              name={CustomerFilterAttribute.CustomerType}
              onSelect={onSelectCustomerType}
              label={intl.formatMessage({
                id: 'customers.input_customer_type'
              })}
              defaultValue={filters.customerType}
              options={customerTypeOptions}
              tight
            />
          </SearchGrid>
        </SearchField>
      </Card>
      <FoldableCard open={filters.active}>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.advanced_title" />
        </Heading>
        <CustomerFilters onFilter={onFilter} />
      </FoldableCard>
      {results}
      <CreateCustomerModal
        isOpen={createCustomerModal}
        onClose={toggleCreateCustomerModal}
      />
    </Fragment>
  );
};

export default CustomerSearchPage;
