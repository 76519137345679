import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeaderGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    margin: 0;
  }
`;
