import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CustomerAttributes, ProductRegistration } from 'models';

import InfoItem from 'components/UI/InfoItem';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';

import { ButtonGrid, DetailGroup, SelectList, SelectListItem } from './styled';
import { showInfoToast } from 'utils/toast';

export type DeleteRegistrationsProps = {
  registrationIds: string[];
  iprId: string;
  removeWarrantyCondition: boolean;
};

type FormValues = {
  registrations: string[] | string;
  removeWarrantyCondition: boolean;
};

type Props = {
  customer: CustomerAttributes;
  productRegistration: ProductRegistration | null;
  onDeleteRegistrations: (props: DeleteRegistrationsProps) => void;
  onClose: () => void;
  open: boolean;
};

const EditProductModal: FC<Props> = ({
  customer,
  productRegistration,
  open,
  onClose,
  onDeleteRegistrations
}) => {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<any> = useCallback(
    ({ registrations, removeWarrantyCondition }: FormValues) => {
      if (!registrations?.length) {
        return setError('registrations', {
          message: intl.formatMessage({ id: 'select.required' })
        });
      }

      if (!productRegistration?.product.iprId) {
        return setError('registrations', {
          message: intl.formatMessage({ id: 'product_details.not_found' })
        });
      }

      // Relations can be both string and array if removing more than 1 at once
      onDeleteRegistrations({
        registrationIds: Array.isArray(registrations)
          ? registrations
          : [registrations],
        iprId: productRegistration.product.iprId,
        removeWarrantyCondition
      });
      onClose();
    },
    [onDeleteRegistrations, onClose, setError, intl, productRegistration]
  );

  // Render relations
  const relationsCheckboxes = useMemo(() => {
    if (!productRegistration?.registrations) {
      return null;
    }

    return productRegistration.registrations.map((registration) => {
      const { id, relationshipType } = registration;
      return (
        <SelectListItem key={relationshipType}>
          <Checkbox
            id={relationshipType}
            value={id}
            register={register('registrations')}
            defaultChecked
          />
          <label htmlFor={relationshipType}>{relationshipType}</label>
        </SelectListItem>
      );
    });
  }, [productRegistration, register]);

  const warrantyConditionCheckbox = useMemo(() => {
    if (!productRegistration?.product?.iprId) {
      return null;
    }

    return (
      <SelectListItem key="removeWarrantyCondition">
        <Checkbox
          id="removeWarrantyCondition"
          register={register('removeWarrantyCondition')}
          defaultChecked
        />
        <label htmlFor="removeWarrantyCondition">
          <FormattedMessage id="modal.remove_product_warranty_condition_text" />
        </label>
      </SelectListItem>
    );
  }, [productRegistration, register]);

  // Show error toast
  useEffect(() => {
    const error = errors.relationships;
    if (error) {
      showInfoToast(error.message as string);
      clearErrors();
    }
  }, [intl, errors, clearErrors]);

  // Details
  const details = useMemo(() => {
    if (!productRegistration?.product) {
      return [];
    }

    const { firstName, lastName } = customer;
    const { brand, modelName, pnc, serialNumber } = productRegistration.product;
    return [
      {
        id: 1,
        title: 'customer_details.title',
        value: `${firstName} ${lastName}`
      },
      {
        id: 2,
        title: 'customers.product_table_title',
        value: `${brand} ${modelName}`
      },
      {
        id: 3,
        title: 'customers.product_table_pnc',
        value: pnc
      },
      {
        id: 4,
        title: 'customers.product_table_serial',
        value: serialNumber
      }
    ];
  }, [productRegistration, customer]);

  return (
    <EditModal title="modal.remove_product_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormattedMessage id="modal.remove_product_text" />
        <DetailGroup>
          {details.map(({ id, title, value }) => (
            <InfoItem
              key={id}
              title={intl.formatMessage({ id: title })}
              value={value ?? '–'}
            />
          ))}
        </DetailGroup>
        <DetailGroup>
          <div>
            <FormattedMessage id="modal.remove_product_relations" />:
            <SelectList>{relationsCheckboxes}</SelectList>
          </div>
          <div>
            <FormattedMessage id="modal.remove_product_warranty_condition" />:
            <SelectList>{warrantyConditionCheckbox}</SelectList>
          </div>
        </DetailGroup>
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button submit>
            <FormattedMessage id="modal.btn_remove" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};

export default EditProductModal;
