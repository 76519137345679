import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'router/Routes';
import { BatchJob } from 'models';
import { getBatchJobStatusColors } from 'utils/batchJob';
import { formatDate } from 'utils/date';

import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Tag from 'components/UI/Tag';

const headerCells: HeaderCell[] = [
  {
    intl: 'batch_jobs.table_header_name',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_type',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_status',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_run_type',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_created',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_started',
    sortable: true
  },
  {
    intl: 'batch_jobs.table_header_cancelled',
    sortable: true
  }
];

type Props = {
  data: BatchJob[];
};

const BatchJobTable: FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (id: string) => navigate(routes.batchJobDetailsLink(id)),
    [navigate]
  );

  return (
    <SortableTable
      headerCells={headerCells}
      sortIndex={4}
      bodyRows={data.map((job) => {
        const { status } = job.attributes;
        const { background, color } = getBatchJobStatusColors(status);

        return {
          name: job.attributes.name || '–',
          type: job.attributes.type || '–',
          status: (
            <Tag background={background} color={color}>
              {status}
            </Tag>
          ),
          runType: job.attributes.dryRun ? 'Test' : 'Live',
          created: job.attributes.createdBy
            ? formatDate(job.attributes.createdBy.timestamp, 'yyyy-MM-dd')
            : '–',
          started: job.attributes.startedBy
            ? formatDate(job.attributes.startedBy.timestamp, 'yyyy-MM-dd')
            : '–',
          cancelled: job.attributes.canceledBy
            ? formatDate(job.attributes.canceledBy.timestamp, 'yyyy-MM-dd')
            : '–',
          link: job.id
        };
      })}
      onClick={onClick}
    />
  );
};

export default BatchJobTable;
