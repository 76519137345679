import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import * as routes from 'router/Routes';
import { BatchJobExtendedAttributes, DIAMUserAccess } from 'models';
import { formatDate } from 'utils/date';
import { getBatchJobStatusColors } from 'utils/batchJob';

import Tag from 'components/UI/Tag';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';
import Heading from 'components/UI/Heading';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import Tooltip from 'components/UI/Tooltip';
import { ConfirmCancelBatchJobModal } from 'components/batchJobs/ConfirmCancelBatchJobModal';
import { ConfirmDeleteBatchJobModal } from 'components/batchJobs/ConfirmDeleteBatchJobModal';

import { ButtonContainer, DetailGroup } from './styled';

type Props = {
  jobId: string;
  data: BatchJobExtendedAttributes;
  access: DIAMUserAccess;
  onStartJob: () => void;
  onRetryJob: () => void;
  onRefreshJob: () => void;
};

export const BatchJobNameCard: FC<Props> = ({
  jobId,
  data,
  access,
  onStartJob,
  onRetryJob,
  onRefreshJob
}) => {
  const intl = useIntl();

  // State
  const [cancelJobModal, setCancelJobModal] = useState<boolean>(false);
  const [removeJobModal, setRemoveJobModal] = useState<boolean>(false);

  // Open / close modals
  const openCancelJobModal = useCallback(() => setCancelJobModal(true), []);
  const closeCancelJobModal = useCallback(() => setCancelJobModal(false), []);
  const openRemoveJobModal = useCallback(() => setRemoveJobModal(true), []);
  const closeRemoveJobModal = useCallback(() => setRemoveJobModal(false), []);
  const onCancelJob = useCallback(() => {
    setCancelJobModal(false);
    onRefreshJob();
  }, [onRefreshJob]);
  const onRemoveJob = useCallback(() => {
    setRemoveJobModal(false);
    onRefreshJob();
  }, [onRefreshJob]);

  // Details
  const details = useMemo(() => {
    const { background, color } = getBatchJobStatusColors(data.status);

    return [
      {
        id: 1,
        title: 'batch_job_details.type',
        value: data.type || '–'
      },
      {
        id: 2,
        title: 'batch_job_details.status',
        value: (
          <Tag background={background} color={color}>
            {data.status}
          </Tag>
        )
      },
      {
        id: 3,
        title: 'batch_job_details.run_type',
        value: data.dryRun ? 'Test' : 'Live'
      },
      {
        id: 4,
        title: 'batch_job_details.created',
        value: data.createdBy ? formatDate(data.createdBy.timestamp) : '–'
      },
      {
        id: 5,
        title: 'batch_job_details.started',
        value: data.startedBy ? formatDate(data.startedBy.timestamp) : '–'
      },
      {
        id: 6,
        title: 'batch_job_details.total',
        value: data.progress?.total || '–'
      },
      {
        id: 7,
        title: 'batch_job_details.processed',
        value: data.progress?.processed || '–'
      },
      {
        id: 8,
        title: 'batch_job_details.successful',
        value: data.progress?.success || '–'
      },
      {
        id: 9,
        title: 'batch_job_details.failed',
        value: data.progress?.fail || '–'
      }
    ];
  }, [data]);

  // Menu
  const menu = useMemo(() => {
    const { status } = data;
    const isStartable = status === 'CREATED';
    const isCancelable = status === 'WAITING' || status === 'PROCESSING';
    const isRetryable =
      status === 'COMPLETED_WITH_ERRORS' || status === 'CANCELED';

    return [
      ...(isStartable
        ? [
            {
              id: 1,
              text: intl.formatMessage({
                id: 'batch_job_details.btn_start'
              }),
              onClick: onStartJob
            }
          ]
        : []),
      ...(isCancelable
        ? [
            {
              id: 2,
              text: intl.formatMessage({
                id: 'batch_job_details.btn_cancel'
              }),
              onClick: openCancelJobModal
            }
          ]
        : []),
      ...(access.isBatchApiAdmin && isRetryable
        ? [
            {
              id: 3,
              text: intl.formatMessage({
                id: 'batch_job_details.btn_retry'
              }),
              onClick: onRetryJob
            }
          ]
        : []),
      {
        id: 4,
        text: intl.formatMessage({
          id: 'batch_job_details.btn_remove'
        }),
        onClick: openRemoveJobModal
      }
    ];
  }, [
    onStartJob,
    onRetryJob,
    openCancelJobModal,
    openRemoveJobModal,
    intl,
    access,
    data
  ]);

  return (
    <>
      <Card>
        <CardHeader titleIntl="batch_jobs.title" navUrl={routes.BATCH_JOBS}>
          <ButtonContainer>
            <Tooltip
              paragraph={intl.formatMessage({
                id: 'batch_job_details.btn_refresh'
              })}
            >
              <IconButton onClick={onRefreshJob}>
                <Icon type={IconType.Refresh} themeType="grey6" />
              </IconButton>
            </Tooltip>
            <DropdownMenu menu={menu}>
              <IconButton>
                <Icon type={IconType.More} themeType="grey6" />
              </IconButton>
            </DropdownMenu>
          </ButtonContainer>
        </CardHeader>
        <Heading>{data.name}</Heading>
        <DetailGroup>
          {details.map(({ id, title, value }) => (
            <InfoItem
              key={id}
              title={intl.formatMessage({ id: title })}
              value={value}
            />
          ))}
        </DetailGroup>
      </Card>
      <ConfirmCancelBatchJobModal
        jobId={jobId}
        open={cancelJobModal}
        close={closeCancelJobModal}
        onCancel={onCancelJob}
      />
      <ConfirmDeleteBatchJobModal
        jobId={jobId}
        open={removeJobModal}
        close={closeRemoveJobModal}
        onDelete={onRemoveJob}
      />
    </>
  );
};
