// Unprotected routes
export const ROOT = '/';
export const LOGIN = '/login';

// Protected routes
export const CUSTOMERS = '/customers';
export const PRODUCTS = '/products';
export const SERVICE_PLANS = '/service-plans';
export const SERVICE_CONTRACTS = '/service-contracts';
export const USERS = '/users';
export const USER_STATISTICS = '/users/statistics';
export const BATCH_JOBS = '/batch-jobs';

// Links
export const customerDetailsLink = (customerId?: string) =>
  `/customers/${customerId}`;
export const productDetailsLink = (iprId?: string) => `/products/${iprId}`;
export const batchJobDetailsLink = (jobId?: string) => `/batch-jobs/${jobId}`;
export const productServicePlanLink = (iprId?: string) =>
  `/products/${iprId}/service-plans`;
export const serviceContractDetailsLink = (contractId?: string) =>
  `/service-contracts/${contractId}`;
export const userDetailsLink = (userId?: string) => `/users/${userId}`;
