import { useMemo } from 'react';
import {
  authSelector,
  serviceContractDetailsSelector,
  metaSelector
} from 'store';
import {
  getSalesCompanyCodesFromSellerId,
  getSellerId,
  getUserRoles,
  validateAzureEditPermission
} from 'utils/roles';
import { useAppSelector } from './redux';

export const useValidateAzureEditContract = () => {
  const { session } = useAppSelector(authSelector);
  const serviceContract = useAppSelector(serviceContractDetailsSelector);
  const meta = useAppSelector(metaSelector);

  // Can edit
  return useMemo(() => {
    const roles = getUserRoles(session);
    const sellerId = getSellerId(serviceContract.data);

    const salesCompanyCodes = getSalesCompanyCodesFromSellerId(
      meta.data.salesCompanyCodes.write,
      sellerId
    );

    return validateAzureEditPermission(roles.azure, salesCompanyCodes);
  }, [session, serviceContract, meta]);
};
