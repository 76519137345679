import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  cancelBatchJob,
  cancelBatchJobSelector,
  resetCancelBatchJob
} from 'store';
import { showSuccessToast } from 'utils/toast';
import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import Loader from 'components/UI/Loader';

import { ButtonGrid } from './styled';

type Props = {
  jobId: string;
  open: boolean;
  close: () => void;
  onCancel: () => void;
};

export const ConfirmCancelBatchJobModal: FC<Props> = ({
  jobId,
  open,
  close,
  onCancel
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess } = useAppSelector(cancelBatchJobSelector);

  // Cancel
  const onCancelBatchJob = useCallback(() => {
    if (jobId) {
      dispatch(cancelBatchJob(jobId));
    }
  }, [dispatch, jobId]);

  // Success
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetCancelBatchJob());
      showSuccessToast(
        intl.formatMessage({ id: 'batch_job_details.cancel_success' })
      );
      onCancel();
      close();
    }
  }, [close, dispatch, intl, onCancel, isSuccess]);

  return (
    <EditModal open={open} title="batch_job_details.cancel_title" close={close}>
      <p>
        <FormattedMessage id="batch_job_details.cancel_description" />
      </p>
      {isLoading && <Loader padding center />}
      <ButtonGrid>
        <Button
          onClick={close}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button onClick={onCancelBatchJob} disabled={isLoading}>
          <FormattedMessage id="modal.btn_confirm" />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};
