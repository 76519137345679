import styled, { DefaultTheme } from 'styled-components';

type Props = {
  $isDragAccept: boolean;
  $isDragReject: boolean;
  $isFocused: boolean;
  theme: DefaultTheme;
};

function getBorderColor(props: Props): string {
  const { grey3, success, error, info } = props.theme.colors;

  if (props.$isDragAccept) {
    return success;
  }
  if (props.$isDragReject) {
    return error;
  }
  if (props.$isFocused) {
    return info;
  }
  return grey3;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  border-width: 1px;
  border-radius: 16px;
  border-color: ${getBorderColor};
  border-style: dashed;
  outline: none;
  min-height: 130px;
  padding: 16px;
  cursor: pointer;
  transition: border 0.24s ease-in-out;

  li {
    list-style-type: none;
  }
`;

export const Text = styled.p`
  max-width: 200px;
  text-align: center;
  margin: 0;
`;

export const SupportedFormats = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey4};
  padding: 4px 16px;
  margin: 0 0 8px;
`;
