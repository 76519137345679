import { CustomerAttributes, ExternalCompany } from 'models';

export function isOrganizationCustomer(customer: CustomerAttributes) {
  return customer.type === 'OrganizationCustomer';
}

export function getCustomerEmail(customer: CustomerAttributes): string {
  const { eContact } = customer;
  if (eContact?.emailContacts.length) {
    return eContact.emailContacts[0].emailAddress || '';
  }
  return '';
}

export function getCustomerPhone(customer: CustomerAttributes): string {
  const { eContact } = customer;
  if (eContact?.phoneContacts.length) {
    return eContact.phoneContacts[0].phoneNumber || '';
  }
  return '';
}

export function getCustomerName(customer: CustomerAttributes) {
  const { companyName, firstName, lastName } = customer;
  if (isOrganizationCustomer(customer)) {
    return companyName;
  }
  return `${firstName} ${lastName}`;
}

export function getExternalCompanyInfo(item: ExternalCompany) {
  const { companyName, primaryAddress, customerId } = item.attributes;
  const { street, zipCode, city } = primaryAddress;
  return {
    id: item.id,
    disabled: !!customerId,
    primary: companyName,
    secondary: street ? `${street}, ${zipCode} ${city}` : city
  };
}
