export async function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result?.toString().split(',')[1];
      if (!base64String) {
        reject(new Error('Failed to convert file to base64'));
        return;
      }
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
}
