import { toast, ToastOptions } from 'react-toastify';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export function showToast(
  message: string,
  type = ToastType.ERROR,
  options: ToastOptions = {}
) {
  toast[type](message, {
    position: 'top-center',
    hideProgressBar: false,
    draggable: false,
    autoClose: 16000,
    ...options
  });
}

export function showErrorToast(message: string, options: ToastOptions = {}) {
  toast.error(message, {
    position: 'top-center',
    draggable: false,
    autoClose: false,
    ...options
  });
}
export function showWarningToast(message: string, options: ToastOptions = {}) {
  return showToast(message, ToastType.WARNING, options);
}
export function showInfoToast(message: string, options: ToastOptions = {}) {
  return showToast(message, ToastType.INFO, options);
}
export function showSuccessToast(message: string, options: ToastOptions = {}) {
  return showToast(message, ToastType.SUCCESS, options);
}
