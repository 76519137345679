import {
  ServiceContractFilter,
  ServiceContractFilterAttribute,
  FilterParam,
  ProductFilter,
  ProductFilterAttribute
} from 'models';
import { regExp } from 'utils/constants';

// Create filters
export function createFilters<T extends FilterParam>(
  filters: Record<string, string>
): T[] {
  const list: T[] = [];
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      list.push({
        attribute: key,
        value
      } as T);
    }
  });
  return list;
}

export function validateField(condition: boolean, message: string) {
  if (!condition) {
    return false;
  }

  return {
    value: true,
    message
  };
}

export function defineProductFilterAttribute(input: string): ProductFilter {
  if (regExp.email.test(input)) {
    return {
      attribute: ProductFilterAttribute.Email,
      value: input
    };
  }
  return {
    attribute: ProductFilterAttribute.IprId,
    value: input
  };
}

export function createSearchFilterAttribute(
  input: string
): ServiceContractFilter[] {
  const list: ServiceContractFilter[] = [];

  // Check EMAIL
  if (regExp.email.test(input)) {
    list.push({
      attribute: ServiceContractFilterAttribute.Email,
      value: input
    });
    return list;
  }

  // Check SELLER ID
  if (
    input.charAt(3) === '-' &&
    regExp.allCapLetters.test(input.substring(3, 0))
  ) {
    list.push({
      attribute: ServiceContractFilterAttribute.SellerId,
      value: input
    });
    return list;
  }

  // CHECK LENGTH
  switch (input.length) {
    case 8:
      list.push({
        attribute: ServiceContractFilterAttribute.Id,
        value: input
      });
      break;
    case 9:
      list.push({
        attribute: ServiceContractFilterAttribute.ServiceContractProduct,
        value: input
      });
      break;
    case 17:
      list.push({
        attribute: ServiceContractFilterAttribute.CustomerId,
        value: input
      });
      break;
    case 36:
      list.push({
        attribute: ServiceContractFilterAttribute.IprId,
        value: input
      });
  }
  return list;
}
