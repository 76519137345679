import { FC, useMemo } from 'react';

import { Consent } from 'models';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import Loader from 'components/UI/Loader';
import Tag from 'components/UI/Tag';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'consents.table_title'
  },
  {
    sortable: true,
    intl: 'consents.table_status'
  },
  {
    sortable: true,
    intl: 'customers.table_date'
  }
];

type Props = {
  data: Consent[];
  isLoading: boolean;
};

const ConsentCard: FC<Props> = ({ data, isLoading }) => {
  // Render
  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={data.map(({ attributes }) => ({
          purpose: attributes.consentPurpose,
          status: <Tag>{attributes.status}</Tag>,
          date: formatDate(attributes.timestamp)
        }))}
        emptyTextId="consents.empty"
      />
    );
  }, [isLoading, data]);

  return (
    <Card>
      <CardHeader titleIntl="consents.title"></CardHeader>
      {table}
    </Card>
  );
};

export default ConsentCard;
