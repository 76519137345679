import * as config from 'config';
import { ConsentResponse } from 'models';
import { apiFetch } from 'utils/fetch';

export async function findCustomerConsents(
  customerId: string
): Promise<ConsentResponse> {
  const url = `${config.backendApi}/consents/customers/${customerId}`;

  return apiFetch<any>(url);
}

export async function findCustomerConsentTypes(): Promise<ConsentResponse> {
  const url = `${config.backendApi}/proxy/customers/consents/consent-types?filter[serviceId]=husqvarna`;

  return apiFetch<any>(url);
}

export async function findUserConsents(
  userId: string
): Promise<ConsentResponse> {
  const url = `${config.backendApi}/consents/users/${userId}`;

  return apiFetch<any>(url);
}
