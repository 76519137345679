import {
  BatchJobCreateBody,
  BatchJobFilter,
  BatchJobListResponse,
  BatchJobResponse
} from 'models';
import { apiFetch, createParams } from 'utils/fetch';
import * as config from 'config';
import { convertFileToBase64 } from 'utils/file';

export async function getBatchJobList(filters: BatchJobFilter[] = []) {
  const url = createParams(`${config.backendApi}/batch-jobs`, filters);
  const json = await apiFetch<BatchJobListResponse>(url);

  return json;
}

export async function getBatchJobById(id: string) {
  const url = `${config.backendApi}/batch-jobs/${id}?include=job-item`;
  const json = await apiFetch<BatchJobResponse>(url);

  return json;
}

export async function createBatchJob(body: BatchJobCreateBody) {
  const { file, name, type, webhook, dryRun } = body;

  const url = `${config.backendApi}/batch-jobs`;

  // Convert file to base64
  const base64File = await convertFileToBase64(file);

  const json = await apiFetch<BatchJobResponse>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'job',
        attributes: {
          name,
          type,
          webhook,
          dryRun,
          file: base64File
        }
      }
    })
  });

  return json;
}

export async function startBatchJob(id: string) {
  const url = `${config.backendApi}/batch-jobs/${id}/start`;

  const json = await apiFetch<BatchJobResponse>(url, {
    method: 'POST'
  });

  return json;
}

export async function cancelBatchJob(id: string, reason?: string) {
  const url = `${config.backendApi}/batch-jobs/${id}/cancel`;

  const json = await apiFetch<BatchJobResponse>(url, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'job',
        attributes: reason ? { reason } : {}
      }
    })
  });

  return json;
}

export async function retryBatchJob(id: string) {
  const url = `${config.backendApi}/batch-jobs/${id}/retry`;

  const json = await apiFetch<BatchJobResponse>(url, {
    method: 'POST'
  });

  return json;
}

export async function removeBatchJob(id: string) {
  const url = `${config.backendApi}/batch-jobs/${id}`;

  await apiFetch(url, {
    method: 'DELETE'
  });

  return true;
}

export async function getBatchJobExample(): Promise<string> {
  const response = await fetch(
    `${config.backendApi}/documentation/batch-job-example`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'text/csv'
      }
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch batch job example');
  }

  return response.text();
}
