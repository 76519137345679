import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Group, UserGroupData } from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addUserGroup,
  addUserGroupReset,
  addUserRole,
  addUserRoleReset,
  fetchUserGroups,
  fetchUserRoles,
  removeUserGroup,
  removeUserGroupReset,
  removeUserRole,
  removeUserRoleReset,
  userGroupSelector,
  userRoleSelector
} from 'store';
import { showSuccessToast } from 'utils/toast';
import { deleteItemFromArray } from 'utils/array';

import Loader from 'components/UI/Loader';
import InfoItem from 'components/UI/InfoItem';
import { SelectField, OptionType } from 'components/UI/SelectField';
import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';
import Tag from 'components/UI/Tag';

import { ButtonGrid, SelectGroupContainer, TagGrid } from './styled';

type Props = {
  fetchData: (refetch: boolean) => void;
  fetchAuditLogs: () => void;
  confirmDeleteUser: () => void;
  onClose: () => void;
  open: boolean;
  userGroups: Group[];
  userRoles: string[];
  userId: string;
};

const EditUserPermissionsModal: FC<Props> = ({
  fetchData,
  fetchAuditLogs,
  onClose,
  confirmDeleteUser,
  open,
  userGroups,
  userRoles,
  userId
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { groups, addGroup, removeGroup } = useAppSelector(userGroupSelector);
  const { roles, addRole, removeRole } = useAppSelector(userRoleSelector);

  // State
  const [activeGroups, setActiveGroups] = useState<Group[]>([]);
  const [activeRoles, setActiveRoles] = useState<string[]>([]);

  // Fetch all DIAM groups and roles if they have not already been fetched
  useEffect(() => {
    dispatch(fetchUserGroups());
    dispatch(fetchUserRoles());

    return () => {
      dispatch(addUserGroupReset());
      dispatch(removeUserGroupReset());
      dispatch(addUserRoleReset());
      dispatch(removeUserRoleReset());
    };
  }, [dispatch]);

  // Set default groups / roles
  useEffect(() => {
    setActiveGroups(userGroups);
    setActiveRoles(userRoles);
  }, [userGroups, userRoles]);

  // Fetch if add was successful
  useEffect(() => {
    if (addGroup.isSuccess) {
      fetchData(true);
      fetchAuditLogs();
      showSuccessToast(
        intl.formatMessage(
          { id: 'modal.user_permission_group_add_success' },
          { name: `${addGroup.data?.name} (${addGroup.data?.value})` }
        )
      );
    }
  }, [fetchData, addGroup, intl, fetchAuditLogs]);

  // Fetch if remove was successful
  useEffect(() => {
    if (removeGroup.isSuccess) {
      fetchData(true);
      fetchAuditLogs();
      showSuccessToast(
        intl.formatMessage(
          { id: 'modal.user_permission_group_remove_success' },
          { name: `${removeGroup.data?.name} (${removeGroup.data?.value})` }
        )
      );
    }
  }, [fetchData, removeGroup, intl, fetchAuditLogs]);

  // Fetch if add was successful
  useEffect(() => {
    if (addRole.isSuccess) {
      fetchData(true);
      fetchAuditLogs();
      showSuccessToast(
        intl.formatMessage(
          { id: 'modal.user_permission_role_add_success' },
          { name: addRole.data }
        )
      );
    }
  }, [fetchData, addRole, intl, fetchAuditLogs]);

  // Fetch if remove was successful
  useEffect(() => {
    if (removeRole.isSuccess) {
      fetchData(true);
      fetchAuditLogs();
      showSuccessToast(
        intl.formatMessage(
          { id: 'modal.user_permission_role_remove_success' },
          { name: removeRole.data }
        )
      );
    }
  }, [fetchData, removeRole, intl, fetchAuditLogs]);

  // Remove group
  const onRemoveGroup = useCallback(
    (group: UserGroupData) => () => {
      if (userGroups.length === 1) {
        return confirmDeleteUser();
      }
      dispatch(
        removeUserGroup(userId, {
          name: group.name,
          value: group.value
        })
      );
      const index = userGroups.findIndex((item) => item.id === group.value);
      setActiveGroups(deleteItemFromArray(activeGroups, index).newArray);
    },
    [dispatch, confirmDeleteUser, userId, userGroups, activeGroups]
  );

  // Add group
  const onAddGroup = useCallback(
    (option: OptionType) =>
      dispatch(
        addUserGroup(userId, {
          name: option.label,
          value: option.value
        })
      ),
    [dispatch, userId]
  );

  // Remove role
  const onRemoveRole = useCallback(
    (role: string) => () => {
      dispatch(removeUserRole(userId, role));
      const index = activeRoles.indexOf(role);
      setActiveRoles(deleteItemFromArray(activeRoles, index).newArray);
    },
    [dispatch, userId, activeRoles]
  );

  // Add role
  const onAddRole = useCallback(
    (option: OptionType) => dispatch(addUserRole(userId, option.value)),
    [dispatch, userId]
  );

  // Group value
  const userGroupsValue = useMemo(
    () => (
      <TagGrid>
        {activeGroups.length > 0
          ? activeGroups.map((group) => (
              <Tag
                key={group.id}
                onRemove={onRemoveGroup({ name: group.name, value: group.id })}
              >
                {group.name || group.id}
              </Tag>
            ))
          : '-'}
      </TagGrid>
    ),
    [activeGroups, onRemoveGroup]
  );

  // Select user group
  const selectUserGroup = useMemo(() => {
    if (!groups.data) {
      return [];
    }
    if (addGroup.isLoading || removeGroup.isLoading) {
      return <Loader center />;
    }
    const options = groups.data.filter(
      (item) => !userGroups.some((group) => group.id === item.id)
    );
    return (
      <SelectField
        name="groups"
        onSelect={onAddGroup}
        placeholder={intl.formatMessage({ id: 'select.placeholder' })}
        label={intl.formatMessage({ id: 'user_details.groups' })}
        options={options.map((group) => ({
          key: group.id,
          label: group.attributes.name,
          value: group.id
        }))}
        message={intl.formatMessage({
          id: 'modal.user_permission_group_info'
        })}
        enableSearch
      />
    );
  }, [onAddGroup, addGroup, removeGroup, groups, intl, userGroups]);

  // Roles value
  const userRolesValue = useMemo(
    () => (
      <TagGrid>
        {activeRoles.length > 0
          ? activeRoles.map((role) => (
              <Tag key={role} onRemove={onRemoveRole(role)}>
                {role}
              </Tag>
            ))
          : '-'}
      </TagGrid>
    ),
    [activeRoles, onRemoveRole]
  );

  // Select user role
  const selectUserRole = useMemo(() => {
    if (!roles.data) {
      return [];
    }
    if (addRole.isLoading || removeRole.isLoading) {
      return <Loader center />;
    }
    const options = roles.data.filter(
      (item) => !userRoles.some((role) => role === item.id)
    );
    return (
      <SelectField
        name="roles"
        onSelect={onAddRole}
        placeholder={intl.formatMessage({ id: 'select.placeholder' })}
        label={intl.formatMessage({ id: 'user_details.roles' })}
        options={options.map((role) => ({
          key: role.id,
          label: role.id,
          value: role.id
        }))}
        enableSearch
      />
    );
  }, [onAddRole, addRole, removeRole, roles, intl, userRoles]);

  return (
    <EditModal title="modal.user_permission_title" open={open} close={onClose}>
      <p>
        <FormattedMessage id="modal.user_permission_text" />
      </p>

      <InfoItem
        title={intl.formatMessage({ id: 'user_details.groups' })}
        value={userGroupsValue}
      />
      <SelectGroupContainer>{selectUserGroup}</SelectGroupContainer>
      <InfoItem
        title={intl.formatMessage({ id: 'user_details.roles' })}
        value={userRolesValue}
      />
      <SelectGroupContainer>{selectUserRole}</SelectGroupContainer>
      <ButtonGrid>
        <Button onClick={onClose} backgroundColor="transparent" color="primary">
          <FormattedMessage id="modal.btn_close" />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};

export default EditUserPermissionsModal;
