import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { parse } from 'date-fns';

import { ServiceContractAttributes, Warranty } from 'models';
import { useValidateAzureEditProduct } from 'hooks/useValidateAzureEditProduct';
import { isNotActiveOrPending } from 'utils/serviceContracts';
import { formatDate } from 'utils/date';

import { CreateWarrantyModal } from 'components/products/CreateWarrantyModal';
import { ConfirmRemoveWarrantyModal } from 'components/products/ConfirmDeleteWarrantyModal';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';
import InfoItem from 'components/UI/InfoItem';
import EmptyState from 'components/UI/EmptyState';
import Tag from 'components/UI/Tag';

import { DetailGroup } from './styled';

type Props = {
  iprId: string;
  warranty: Warranty | null;
  contracts?: ServiceContractAttributes[];
  descriptionIntl?: string;
  isLoading: boolean;
  onRefetch: () => void;
};

const ProductWarrantyCard: FC<Props> = ({
  iprId,
  warranty,
  isLoading,
  contracts,
  descriptionIntl,
  onRefetch
}) => {
  const intl = useIntl();

  // State
  const [createWarrantyModal, setCreateWarrantyModal] =
    useState<boolean>(false);
  const [removeWarrantyModal, setRemoveWarrantyModal] =
    useState<boolean>(false);

  // Hooks
  const canEdit = useValidateAzureEditProduct({
    allowAnySalesCompanyAdmin: true
  });

  // Actions
  const onToggleCreateWarrantyModal = useCallback(
    () => setCreateWarrantyModal(!createWarrantyModal),
    [createWarrantyModal]
  );
  const onOpenRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModal(true),
    []
  );
  const onCloseRemoveWarrantyModal = useCallback(
    () => setRemoveWarrantyModal(false),
    []
  );

  // Details
  const renderDetails = useCallback(
    (data: Warranty) => {
      const { id, attributes } = data;

      const list = [
        {
          id: 1,
          title: 'product_details.warranty_table_id',
          text: id
        },
        {
          id: 2,
          title: 'product_details.warranty_table_status',
          text: (
            <Tag
              background={attributes.verified ? 'success' : 'error'}
              color="onPrimary"
            >
              {attributes.verified ? 'VERIFIED' : 'UNVERIFIED'}
            </Tag>
          )
        },
        {
          id: 3,
          title: 'product_details.warranty_table_created_date',
          text:
            attributes.createDate &&
            formatDate(
              parse(attributes.createDate, 'yyyyMMdd', new Date()).getTime(),
              'yyyy-MM-dd'
            )
        },
        {
          id: 4,
          title: 'product_details.warranty_table_period',
          text: attributes.warrantyPeriod
        },
        {
          id: 5,
          title: 'product_details.warranty_table_start_date',
          text:
            attributes.soldDate &&
            formatDate(
              parse(attributes.soldDate, 'yyyyMMdd', new Date()).getTime(),
              'yyyy-MM-dd'
            )
        },
        {
          id: 6,
          title: 'product_details.warranty_table_end_date',
          text:
            attributes.expireDate &&
            formatDate(
              parse(attributes.expireDate, 'yyyyMMdd', new Date()).getTime(),
              'yyyy-MM-dd'
            )
        }
      ];
      return list.map(({ id, title, text }) => (
        <InfoItem
          key={id}
          title={intl.formatMessage({ id: title })}
          value={text || '—'}
        />
      ));
    },
    [intl]
  );

  // Content
  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center padding />;
    }

    if (!warranty) {
      return (
        <EmptyState icon={IconType.Contract}>
          <FormattedMessage id="product_details.warranty_not_found" />
        </EmptyState>
      );
    }
    return <DetailGroup>{renderDetails(warranty)}</DetailGroup>;
  }, [renderDetails, isLoading, warranty]);

  // Card button
  const cardButton = useMemo(() => {
    if (canEdit && !warranty) {
      return (
        <IconButton onClick={onToggleCreateWarrantyModal}>
          <Icon type={IconType.Add} themeType="grey6" />
        </IconButton>
      );
    }
    if (canEdit && contracts && isNotActiveOrPending(contracts)) {
      return (
        <IconButton onClick={onOpenRemoveWarrantyModal}>
          <Icon type={IconType.Remove} />
        </IconButton>
      );
    }
    return null;
  }, [
    onOpenRemoveWarrantyModal,
    onToggleCreateWarrantyModal,
    canEdit,
    contracts,
    warranty
  ]);

  return (
    <Card>
      <CardHeader
        titleIntl="product_details.warranty_title"
        descriptionIntl={descriptionIntl}
      >
        {cardButton}
      </CardHeader>
      {content}
      <CreateWarrantyModal
        iprId={iprId}
        open={createWarrantyModal}
        onCreate={onRefetch}
        onClose={onToggleCreateWarrantyModal}
      />
      <ConfirmRemoveWarrantyModal
        iprId={iprId}
        open={removeWarrantyModal}
        close={onCloseRemoveWarrantyModal}
        onRemove={onRefetch}
      />
    </Card>
  );
};

export default ProductWarrantyCard;
