import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  findUsersBySearch,
  setUserSearchFiltersActive,
  setUserSearchFilters,
  userSearchSelector,
  loadMoreResults
} from 'store';
import { regExp } from 'utils/constants';
import { useDiamAccess } from 'hooks/useDiamAccess';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { UserFilter, UserFilterData } from 'models';
import * as routes from 'router/Routes';

import Card from 'components/UI/Card';
import { IconType } from 'components/UI/Icon';
import FoldableCard from 'components/UI/FoldableCard';
import UserFilters from 'components/users/UserFilters';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import { SelectField, OptionType } from 'components/UI/SelectField';
import SearchField from 'components/UI/SearchField';
import UserTable from 'components/users/UserTable';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import Button from 'components/UI/Button';

import { LoadMore, NoItems, ResultHeader, SearchGrid } from './styled';

const UsersPage: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAnyGlobalAdmin, groups } = useDiamAccess();
  const { data, isLoading, isLoadingMore, filters, pagination } =
    useAppSelector(userSearchSelector);

  // Search users
  const searchUsers = useCallback(
    (data: UserFilterData) => {
      dispatch(findUsersBySearch(data));
      dispatch(setUserSearchFilters(data));
    },
    [dispatch]
  );

  // Search
  const onSearch = useCallback(
    (value: string) => {
      if (regExp.uuid.test(value)) {
        return navigate(routes.userDetailsLink(value));
      }
      searchUsers({
        ...filters.data,
        username: value,
        ...(!isAnyGlobalAdmin && {
          group: filters.data.group ?? groups[0]?.id ?? null
        })
      });
    },
    [navigate, searchUsers, filters, isAnyGlobalAdmin, groups]
  );

  // On set search group
  const onSetGroup = useCallback(
    (option: OptionType) => {
      dispatch(setUserSearchFilters({ group: option.value }));
    },
    [dispatch]
  );

  // Filter search
  const onFilter = useCallback(
    (data: Partial<UserFilterData>) => {
      searchUsers({ ...filters.data, ...data });
    },
    [searchUsers, filters]
  );

  // Clear filters
  const onClear = useCallback(
    (data: Partial<UserFilterData>) => {
      dispatch(setUserSearchFilters(data));
    },
    [dispatch]
  );

  // Clear search value
  const onClearSearchValue = useCallback(() => {
    dispatch(setUserSearchFilters({ username: '' }));
  }, [dispatch]);

  // Load more
  const onLoadMore = useCallback(() => {
    const { offset = 0, limit } = pagination;
    const updated = { limit, offset: limit + offset };
    dispatch(loadMoreResults(filters.data, updated));
  }, [dispatch, pagination, filters.data]);

  // Toggle filters
  const toggleFilters = useCallback(() => {
    dispatch(setUserSearchFiltersActive(!filters.isActive));
  }, [dispatch, filters]);

  // Load more
  const renderLoadMore = useCallback(
    (noItems: number) => {
      if (isLoadingMore) {
        return <Loader center />;
      }
      const { limit, offset = 0 } = pagination;
      if (noItems < limit || noItems < limit + offset) {
        return null;
      }
      return (
        <Button
          onClick={onLoadMore}
          backgroundColor="surface"
          color="onSurface"
        >
          <FormattedMessage id="button.load_more" />
        </Button>
      );
    },
    [onLoadMore, isLoadingMore, pagination]
  );

  // Render results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    if (!data) {
      return null;
    }
    if (!data.length) {
      return (
        <EmptyState icon={IconType.Customer} padding>
          <FormattedMessage id="users.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <ResultHeader>
          <Heading type={HeadingType.h3} uppercase>
            <FormattedMessage id="users.user_table_title" />
          </Heading>
          <NoItems>
            <FormattedMessage
              id="pagination.items"
              values={{ value: data.length }}
            />
          </NoItems>
        </ResultHeader>
        <UserTable users={data} />
        <LoadMore>
          <NoItems>
            <FormattedMessage
              id="pagination.items"
              values={{ value: data.length }}
            />
          </NoItems>
          {renderLoadMore(data.length)}
        </LoadMore>
      </Card>
    );
  }, [renderLoadMore, isLoading, data]);

  // Group options
  const groupOptions: OptionType[] = useMemo(
    () =>
      groups.map(({ id, attributes }) => ({
        key: id,
        label: attributes.name,
        value: id
      })),
    [groups]
  );

  // Default group
  const defaultGroup = useMemo(() => {
    if (!isAnyGlobalAdmin) {
      return groups[0]?.id;
    }
  }, [isAnyGlobalAdmin, groups]);

  // Placeholder
  const placeholder = useMemo(() => {
    if (isAnyGlobalAdmin) {
      return intl.formatMessage({
        id: 'users.input_group_placeholder'
      });
    }
  }, [intl, isAnyGlobalAdmin]);

  return (
    <Fragment>
      <Heading>
        <FormattedMessage id="users.title" />
      </Heading>
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.title" />
        </Heading>
        <SearchField
          onSearch={onSearch}
          onClear={onClearSearchValue}
          placeholder={intl.formatMessage({
            id: 'users.search_placeholder'
          })}
          buttons={
            <Button
              onClick={toggleFilters}
              backgroundColor="surface"
              color={filters.isActive ? 'primaryDark' : 'onSurface'}
            >
              <FormattedMessage id="filters.btn" />
            </Button>
          }
        >
          <SearchGrid>
            <SelectField
              name={UserFilter.Group}
              defaultValue={defaultGroup}
              label={intl.formatMessage({ id: 'users.input_group' })}
              placeholder={placeholder}
              options={groupOptions}
              onSelect={onSetGroup}
              enableSearch
              tight
            />
          </SearchGrid>
        </SearchField>
      </Card>
      <FoldableCard open={filters.isActive}>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="filters.title" />
        </Heading>
        <UserFilters onFilter={onFilter} onClear={onClear} />
      </FoldableCard>
      {results}
    </Fragment>
  );
};

export default UsersPage;
