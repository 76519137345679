import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  CustomerPhoneContact,
  CustomerEmailAddress,
  ChangeEmail,
  CustomerUpdate,
  UpdateCustomer,
  ConfirmDeleteCustomer,
  Customer
} from 'models';
import * as routes from 'router/Routes';
import { useDisplayNames } from 'hooks/useDisplayNames';
import { isOrganizationCustomer } from 'utils/customer';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import Icon, { IconType } from 'components/UI/Icon';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Tooltip from 'components/UI/Tooltip';
import InfoItem from 'components/UI/InfoItem';
import CopyField from 'components/UI/CopyField';

import ChangeEmailModal from '../ChangeEmailModal';
import EditCustomerModal from '../EditCustomerModal';
import ConfirmDeleteCustomerModal from '../ConfirmDeleteCustomerModal';

import {
  ContentContainer,
  AvatarBackground,
  NameContainer,
  Title,
  IconText,
  IconContainer,
  DetailGroup,
  ContentColumn
} from './styled';

type Props = {
  customer: Customer;
  onChangeEmail: (newEmail: string, oldEmail: string) => void;
  onCustomerUpdate: (values: CustomerUpdate) => void;
  onConfirmDeleteCustomer: (deleteReason: string) => void;
  changeEmail: ChangeEmail;
  update: UpdateCustomer;
  deleteCustomer: ConfirmDeleteCustomer;
  canEdit: boolean;
};

const CustomerNameCard: FC<Props> = ({
  customer,
  onChangeEmail,
  onCustomerUpdate,
  onConfirmDeleteCustomer,
  changeEmail,
  update,
  deleteCustomer,
  canEdit
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const languageDisplayNames = useDisplayNames('language');

  // State
  const [changeEmailModal, setChangeEmailModal] = useState<boolean>(false);
  const [editCustomerModal, setEditCustomerModal] = useState<boolean>(false);
  const [confirmDeleteCustomerModal, setConfirmDeleteCustomerModal] =
    useState<boolean>(false);

  // Phone numbers
  const renderPhoneNumbers = useCallback((phone?: CustomerPhoneContact[]) => {
    if (!phone?.some((item) => item.phoneNumber)) {
      return '—';
    }
    return phone.map((item, i) => (
      <p key={`phone-${i + 1}`}>{item.phoneNumber}</p>
    ));
  }, []);

  // Email addresses
  const renderEmailAddresses = useCallback(
    (email?: CustomerEmailAddress[]) => {
      if (!email?.some((item) => item.emailAddress)) {
        return '—';
      }
      return email.map((item, i) => (
        <CopyField
          key={`email-${i + 1}`}
          title={intl.formatMessage({ id: 'customer_details.emailAddresses' })}
        >
          {item.emailAddress}
        </CopyField>
      ));
    },
    [intl]
  );

  // Market
  const getCustomerMarket = useCallback((customer: Customer): string => {
    const salesCompanyCodes = customer.attributes.salesCompanyCodes.map(
      (code) => code
    );

    return `${customer.attributes.country} (${salesCompanyCodes.join(', ')})`;
  }, []);

  // Language
  const getCustomerLanguage = useCallback(
    (customer: Customer): string => {
      return languageDisplayNames.of(customer.attributes.language) ?? '-';
    },
    [languageDisplayNames]
  );

  // Details
  const details = useMemo(
    () => [
      {
        id: 1,
        title: 'customer_details.customer_id',
        value: (
          <CopyField
            title={intl.formatMessage({ id: 'customer_details.customer_id' })}
          >
            {customer.id}
          </CopyField>
        )
      },
      {
        id: 2,
        title: 'customer_details.type',
        value: customer.attributes.type
      },
      {
        id: 3,
        title: 'customer_details.market',
        value: getCustomerMarket(customer)
      },
      {
        id: 4,
        title: 'customer_details.language',
        value: getCustomerLanguage(customer)
      },
      {
        id: 5,
        title: 'customer_details.emailAddresses',
        value: renderEmailAddresses(customer.attributes.eContact?.emailContacts)
      },
      {
        id: 6,
        title: 'customer_details.phoneNumbers',
        value: renderPhoneNumbers(customer.attributes.eContact?.phoneContacts)
      },
      ...(isOrganizationCustomer(customer.attributes)
        ? [
            {
              id: 7,
              title: 'customer_details.vat_number',
              value: customer.attributes.vatNumber
            }
          ]
        : [])
    ],
    [
      intl,
      customer,
      getCustomerMarket,
      getCustomerLanguage,
      renderPhoneNumbers,
      renderEmailAddresses
    ]
  );

  // Link DIAM account
  const onClickDIAMAccount = useCallback(() => {
    const {
      userAccount,
      users: [firstUser]
    } = customer.attributes;

    if (userAccount && firstUser) {
      navigate(routes.userDetailsLink(firstUser.iamUserId));
    }
  }, [navigate, customer]);

  // Open / close modals
  const openChangeEmailModal = useCallback(() => setChangeEmailModal(true), []);
  const closeChangeEmailModal = useCallback(
    () => setChangeEmailModal(false),
    []
  );
  const openEditCustomerModal = useCallback(
    () => setEditCustomerModal(true),
    []
  );
  const closeEditCustomerModal = useCallback(
    () => setEditCustomerModal(false),
    []
  );
  const openConfirmDeleteCustomerModal = useCallback(
    () => setConfirmDeleteCustomerModal(true),
    []
  );
  const closeConfirmDeleteCustomerModal = useCallback(
    () => setConfirmDeleteCustomerModal(false),
    []
  );

  // Menu
  const menu = useMemo(
    () => [
      ...(customer.attributes.userAccount
        ? [
            {
              id: 1,
              text: intl.formatMessage({
                id: 'customer_details.btn_change_email'
              }),
              onClick: openChangeEmailModal
            }
          ]
        : []),
      {
        id: 2,
        text: intl.formatMessage({ id: 'customer_details.btn_edit_customer' }),
        onClick: openEditCustomerModal
      },
      {
        id: 3,
        text: intl.formatMessage({
          id: 'customer_details.confirm_delete_customer'
        }),
        onClick: openConfirmDeleteCustomerModal
      }
    ],
    [
      openConfirmDeleteCustomerModal,
      openEditCustomerModal,
      openChangeEmailModal,
      intl,
      customer
    ]
  );

  // Render titles
  const titles = useMemo(() => {
    const { companyName, firstName, lastName } = customer.attributes;
    return <Title>{companyName ?? `${firstName} ${lastName}`}</Title>;
  }, [customer]);

  return (
    <Card>
      <CardHeader titleIntl="customers.title" navUrl={routes.CUSTOMERS}>
        {canEdit && (
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        <ContentColumn>
          <AvatarBackground>
            <Icon
              type={
                IconType[
                  isOrganizationCustomer(customer.attributes)
                    ? 'Retail'
                    : 'Customer'
                ]
              }
              themeType="grey5"
            />
          </AvatarBackground>
          <NameContainer>
            {titles}
            <Tooltip
              position="bottom"
              paragraph={intl.formatMessage({
                id: 'misc.diam_account_info'
              })}
            >
              <IconText onClick={onClickDIAMAccount}>
                <Icon
                  type={
                    customer.attributes.userAccount
                      ? IconType.Active
                      : IconType.Inactive
                  }
                  themeType={
                    customer.attributes.userAccount ? 'success' : 'error'
                  }
                />
                {intl.formatMessage({ id: 'misc.diam_account' })}
                <IconContainer>
                  <Icon type={IconType.Info} themeType={'grey4'} />
                </IconContainer>
              </IconText>
            </Tooltip>
          </NameContainer>
        </ContentColumn>
        <DetailGroup>
          {details.map(({ id, title, value }) => (
            <InfoItem
              key={id}
              title={intl.formatMessage({ id: title })}
              value={value}
            />
          ))}
        </DetailGroup>
      </ContentContainer>
      <ChangeEmailModal
        customer={customer.attributes}
        changeEmail={changeEmail}
        open={changeEmailModal}
        onChangeEmail={onChangeEmail}
        onClose={closeChangeEmailModal}
      />
      <EditCustomerModal
        customer={customer.attributes}
        update={update}
        open={editCustomerModal}
        onCustomerUpdate={onCustomerUpdate}
        onClose={closeEditCustomerModal}
      />
      <ConfirmDeleteCustomerModal
        customer={customer.attributes}
        deleteCustomer={deleteCustomer}
        open={confirmDeleteCustomerModal}
        onConfirmDeleteCustomer={onConfirmDeleteCustomer}
        onClose={closeConfirmDeleteCustomerModal}
      />
    </Card>
  );
};

export default CustomerNameCard;
