import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { resetViewFactoryPinNumber, viewFactoryPinSelector } from 'store';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

type Props = {
  open: boolean;
  close: () => void;
  onFetch: () => void;
};

const ViewFactoryPinModal: FC<Props> = ({ open, close, onFetch }) => {
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const {
    data: factoryPin,
    isLoading,
    isError
  } = useAppSelector(viewFactoryPinSelector);

  // Close
  const onClose = useCallback(() => {
    dispatch(resetViewFactoryPinNumber());
    close();
  }, [dispatch, close]);

  // Update audit log if pin exists
  useEffect(() => {
    if (factoryPin) {
      onFetch();
    }
  }, [onFetch, factoryPin]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    if (!factoryPin || isError) {
      return (
        <EmptyState icon={IconType.Info} padding>
          <FormattedMessage id="modal.view_factory_pin_not_found" />
        </EmptyState>
      );
    }

    return (
      <InputField
        type="password"
        description="modal.view_factory_pin_input_description"
        defaultValue={factoryPin}
        disabled
      />
    );
  }, [factoryPin, isError, isLoading]);

  return (
    <EditModal title="modal.view_factory_pin_title" open={open} close={onClose}>
      <p>{intl.formatMessage({ id: 'modal.view_factory_pin_description' })}</p>
      {content}
    </EditModal>
  );
};

export default ViewFactoryPinModal;
