import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as routes from 'router/Routes';
import { Customer, CustomerType } from 'models';
import { TableRowData } from 'models/table';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';

const headerCells: HeaderCell[] = [
  {
    sortable: true,
    intl: 'customers.customer_table_header_id'
  },
  {
    sortable: true,
    intl: 'customers.customer_table_header_name'
  },
  {
    sortable: true,
    intl: 'customers.customer_table_header_phone'
  },
  {
    sortable: true,
    intl: 'customers.customer_table_header_email'
  }
];

type Props = {
  customers: Customer[];
};

const CustomerTable: FC<Props> = ({ customers }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  // On click
  const onClick = useCallback(
    (id: string) => navigate(routes.customerDetailsLink(id)),
    [navigate]
  );

  // Body
  const bodyRows = customers.map(({ id, attributes }) => {
    const {
      type,
      ceid,
      firstName,
      lastName,
      companyName,
      eContact,
      onboardingSource
    } = attributes;
    const name =
      type === CustomerType.OrganizationCustomer
        ? companyName
        : `${firstName} ${lastName}`;
    const phone = eContact.phoneContacts;
    const email = eContact.emailContacts;
    return {
      id: id ?? '–',
      name: name ?? '–',
      phone: phone.length ? phone[0].phoneNumber : '–',
      email: email.length ? email[0].emailAddress : '–',
      link: ceid,
      disabled:
        onboardingSource === 'ERP' &&
        intl.formatMessage({ id: 'customers.details.not.available' })
    } as TableRowData;
  });

  return (
    <SortableTable
      headerCells={headerCells}
      bodyRows={bodyRows}
      onClick={onClick}
    />
  );
};

export default CustomerTable;
