import styled from 'styled-components';

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const DownloadLink = styled.a`
  display: block;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
