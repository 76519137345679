import { useMemo } from 'react';
import { authSelector, customerDetailsSelector } from 'store';
import { getUserRoles, validateAzureEditPermission } from 'utils/roles';
import { useAppSelector } from './redux';

export const useValidateAzureEditCustomer = () => {
  const { session } = useAppSelector(authSelector);
  const { data } = useAppSelector(customerDetailsSelector);

  // Can edit
  return useMemo(() => {
    const roles = getUserRoles(session);
    return validateAzureEditPermission(
      roles.azure,
      data?.attributes.salesCompanyCodes
    );
  }, [session, data]);
};
