import { FC, useEffect, useCallback, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  batchJobDetailsSelector,
  fetchBatchJobById,
  resetBatchDetailsJob
} from 'store';

import {
  startBatchJob,
  retryBatchJob
} from 'store/batchJobState/batchJobState';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import { IconType } from 'components/UI/Icon';

import { useDiamAccess } from 'hooks/useDiamAccess';
import { BatchJobNameCard } from 'components/batchJobs/BatchJobNameCard';
import { BatchJobItemTable } from 'components/batchJobs/BatchJobItemTable';

const CustomerDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const { data, isLoading, error } = useAppSelector(batchJobDetailsSelector);

  // Hooks
  const diamAccess = useDiamAccess();

  // Fetch batch job
  const fetchBatchJob = useCallback(() => {
    if (jobId) {
      dispatch(fetchBatchJobById(jobId));
    }
  }, [dispatch, jobId]);

  // Start job
  const onStartJob = useCallback(() => {
    if (jobId) {
      dispatch(startBatchJob(jobId));
    }
  }, [dispatch, jobId]);

  // Retry job
  const onRetryJob = useCallback(() => {
    if (jobId) {
      dispatch(retryBatchJob(jobId));
    }
  }, [dispatch, jobId]);

  // Fetch batch job
  useEffect(() => {
    if (jobId) {
      dispatch(fetchBatchJobById(jobId));
    }
    return () => {
      dispatch(resetBatchDetailsJob());
    };
  }, [dispatch, jobId]);

  // Loading
  if (isLoading) {
    return <Loader center padding />;
  }

  // No access
  if (!data && error?.status === 403) {
    return <NoAccessState />;
  }

  // Not found
  if (!data) {
    return (
      <EmptyState icon={IconType.Tune} padding includeParams>
        <FormattedMessage id="batch_job_details.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <BatchJobNameCard
        jobId={data.data.id}
        data={data.data.attributes}
        access={diamAccess}
        onStartJob={onStartJob}
        onRetryJob={onRetryJob}
        onRefreshJob={fetchBatchJob}
      />
      <BatchJobItemTable data={data.included} />
    </Fragment>
  );
};

export default CustomerDetailsPage;
