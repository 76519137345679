import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  fetchServiceContracts,
  serviceContractListSelector,
  setServiceContractFilters,
  exportServiceContracts,
  resetExportServiceContracts
} from 'store';

import { downloadFile } from 'utils/exporting';
import { createSearchFilterAttribute } from 'utils/filtering';
import { showWarningToast, showErrorToast } from 'utils/toast';
import {
  ServiceContractFilter,
  APIError,
  ServiceContractFilterAttribute
} from 'models';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Card from 'components/UI/Card';
import FoldableCard from 'components/UI/FoldableCard';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import ServiceContractTable from 'components/servicecontracts/ServiceContractTable';
import ServiceContractFilters from 'components/servicecontracts/ServiceContractFilters';
import SearchField from 'components/UI/SearchField';
import Pagination, { PagePagination } from 'components/UI/Pagination';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';

import { CardHeaderTop, CardHeaderTopColumn } from './styled';

// Initial pagination
const initialPagination = {
  limit: 50,
  offset: 0,
  page: 1
};

const ServiceContractListPage: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { data, isLoading, filters, exports } = useAppSelector(
    serviceContractListSelector
  );

  // State
  const [pagination, setPagination] =
    useState<PagePagination>(initialPagination);

  // Show toast errors
  const showToastErrors = useCallback((errors: APIError[]) => {
    let timeoutFound = false;
    const errorMessages: string[] = [];
    errors.forEach((error) => {
      if (error.code !== 'operation.timed.out') {
        errorMessages.push(`${error.detail}`);
      }
      if (error.code === 'operation.timed.out' && !timeoutFound) {
        errorMessages.push('CSV export incomplete due to request timeout');
        timeoutFound = true;
      }
    });
    errorMessages.forEach((message) => showWarningToast(message));
  }, []);

  // Export CSV
  useEffect(() => {
    if (exports.data) {
      downloadFile(exports.data, 'service_contract');

      // Handle errors
      if (exports.errors.length) {
        showToastErrors(exports.errors);
      }
    }
    return () => {
      dispatch(resetExportServiceContracts());
    };
  }, [dispatch, showToastErrors, exports]);

  // Get export data
  const onExport = useCallback(() => {
    if (filters.data) {
      const { limit, offset } = pagination;
      dispatch(exportServiceContracts(filters.data, limit, offset));
    }
  }, [dispatch, filters.data, pagination]);

  // Fetch contracts
  const fetchContracts = useCallback(
    (contractFilters: ServiceContractFilter[], offset?: number) => {
      dispatch(
        fetchServiceContracts(
          contractFilters,
          pagination.limit,
          offset ?? pagination.offset
        )
      );
    },
    [dispatch, pagination]
  );

  // Search
  const onSearch = useCallback(
    (value: string) => {
      const searchFilters = createSearchFilterAttribute(value.trim());

      if (!searchFilters.length) {
        return showErrorToast(
          intl.formatMessage({
            id: 'contracts.search_validation_failure'
          })
        );
      }

      if (
        searchFilters.some(
          (filter) => filter.attribute === ServiceContractFilterAttribute.Email
        )
      ) {
        return showErrorToast(
          intl.formatMessage({
            id: 'contracts.search_validation_email'
          })
        );
      }

      setPagination(initialPagination);
      fetchContracts(searchFilters, initialPagination.offset);
    },
    [fetchContracts, intl]
  );

  // Paginate
  const onSetPagination = useCallback(
    (paginate: PagePagination) => {
      setPagination(paginate);
      if (filters.data) {
        fetchContracts(filters.data, paginate.offset);
      }
    },
    [fetchContracts, filters.data]
  );

  // Toggle filters
  const toggleFilters = useCallback(() => {
    dispatch(setServiceContractFilters(!filters.active));
  }, [dispatch, filters.active]);

  // Apply filters
  const onFilter = useCallback(
    (contractFilters: ServiceContractFilter[]) => {
      setPagination(initialPagination);
      fetchContracts(contractFilters, initialPagination.offset);
    },
    [fetchContracts]
  );

  // Reset pagination
  const resetPagination = useCallback(
    () => setPagination(initialPagination),
    []
  );

  // Render results
  const results = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    if (!data) {
      return null;
    }
    if (!data.length && !pagination.offset) {
      return (
        <EmptyState icon={IconType.ServiceContract} padding>
          <FormattedMessage id="contract_details.not_found" />
        </EmptyState>
      );
    }
    return (
      <Card>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="contracts.table_title" />
        </Heading>
        <ServiceContractTable contracts={data} />
        <Pagination
          noItems={data.length}
          pagination={pagination}
          setPagination={onSetPagination}
        />
      </Card>
    );
  }, [data, isLoading, pagination, onSetPagination]);

  return (
    <Fragment>
      <Heading>
        <FormattedMessage id="contracts.title" />
      </Heading>
      <Card>
        <CardHeaderTop>
          <CardHeaderTopColumn>
            <Heading type={HeadingType.h3} uppercase>
              <FormattedMessage id="search.title" />
            </Heading>
          </CardHeaderTopColumn>
          <CardHeaderTopColumn>
            <div>{exports.isLoading && <Loader size="small" />}</div>
            <Button
              size="small"
              backgroundColor="transparent"
              color="primary"
              onClick={onExport}
              disabled={exports.isLoading || !filters.data}
            >
              <FormattedMessage id="contracts.export_btn" />
            </Button>
          </CardHeaderTopColumn>
        </CardHeaderTop>
        <SearchField
          onSearch={onSearch}
          placeholder={intl.formatMessage({
            id: 'contracts.search_placeholder'
          })}
          buttons={
            <Button
              onClick={toggleFilters}
              backgroundColor="surface"
              color={filters.active ? 'primaryDark' : 'onSurface'}
            >
              <FormattedMessage id="search.advanced_btn" />
            </Button>
          }
        />
      </Card>
      <FoldableCard open={filters.active}>
        <Heading type={HeadingType.h3} uppercase>
          <FormattedMessage id="search.advanced_title" />
        </Heading>
        <ServiceContractFilters onFilter={onFilter} onClear={resetPagination} />
      </FoldableCard>
      {results}
    </Fragment>
  );
};

export default ServiceContractListPage;
