import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { store } from './store';
import App from './App';
import * as config from './config';

// Init Sentry
Sentry.init({
  dsn: config.sentryDsn,
  environment: config.environment,
  tracePropagationTargets: [
    'localhost',
    'https://bff.dev-scp.dss.husqvarnagroup.net/v1',
    'https://bff.qa-scp.dss.husqvarnagroup.net/v1',
    'https://bff.scp.dss.husqvarnagroup.net/v1'
  ],
  integrations: [
    Sentry.browserTracingIntegration({}),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: config.environment === 'dev' ? 1.0 : 0.1,
  replaysOnErrorSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
