import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ButtonGrid, TwoColumnGrid } from 'styles';
import {
  companySelectSelector,
  createCustomer,
  customerCreateSelector,
  resetCompanyState,
  resetCustomerCreateState
} from 'store';
import { CustomerType } from 'models';
import { showSuccessToast } from 'utils/toast';
import { regExp } from 'utils/constants';
import * as routes from 'router/Routes';

import countries from 'assets/data/countries.json';
import languages from 'assets/data/languages.json';

import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import InputField from 'components/UI/InputField';
import { SelectField } from 'components/UI/SelectField';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import { CheckboxContainer } from './styled';

type CreateCompanyForm = {
  companyName: string;
  vatNumber: string;
  dunsNumber: string;
  language: string;
  country: string;
  companyEmailAddress: string;
  companyPhoneNumber: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  street: string;
  zipCode: string;
  city: string;
  manuallyVerified: boolean;
};

type Props = {
  onBack: () => void;
};

const ConfirmProCustomer: FC<Props> = ({ onBack }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Redux
  const select = useAppSelector(companySelectSelector);
  const { isSuccess, data, isLoading } = useAppSelector(customerCreateSelector);

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<CreateCompanyForm>({
    defaultValues: {
      companyName: select.data?.attributes.companyName ?? '',
      vatNumber: '',
      dunsNumber: select.data?.id ?? '',
      language: '',
      country: select.data?.attributes.countryCode,
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      street: select.data?.attributes.primaryAddress.street ?? '',
      zipCode: select.data?.attributes.primaryAddress.zipCode ?? '',
      city: select.data?.attributes.primaryAddress.city ?? '',
      manuallyVerified: false
    }
  });

  // Watch
  const duns = watch('dunsNumber');

  // Success
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        intl.formatMessage({ id: 'modal.create_customer_success' })
      );
      navigate(`${routes.CUSTOMERS}/${data?.id}`);
      dispatch(resetCustomerCreateState());
      dispatch(resetCompanyState());
    }
  }, [dispatch, navigate, data, intl, isSuccess]);

  // Create
  const onSubmit: SubmitHandler<CreateCompanyForm> = useCallback(
    (values) => {
      dispatch(
        createCustomer({
          companyName: values.companyName,
          dunsNumber: values.dunsNumber,
          vatNumber: values.vatNumber,
          country: values.country,
          language: values.language,
          type: CustomerType.OrganizationCustomer,
          companyRegistrationNumberVerified: false,
          userAccount: false,
          primaryContact: {
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress.trim(),
            phoneNumber: values.phoneNumber.trim()
          },
          eContact: {
            emailContacts: [
              { emailAddress: values.companyEmailAddress.trim() }
            ],
            phoneContacts: values.companyPhoneNumber
              ? [{ phoneNumber: values.companyPhoneNumber.trim() }]
              : []
          },
          manuallyVerified: values.manuallyVerified
        })
      );
    },
    [dispatch]
  );

  // Country options
  const countryOptions = useMemo(() => {
    return countries.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  // Language options
  const languageOptions = useMemo(() => {
    return languages.map(({ name, code }) => ({
      key: code,
      label: name,
      value: code
    }));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? (
        <Loader center padding />
      ) : (
        <>
          <InputField
            type="text"
            description="customers.input_company_name"
            register={register('companyName', {
              required: {
                value: true,
                message: intl.formatMessage({ id: 'input.required' })
              }
            })}
            error={errors.companyName}
          />
          <TwoColumnGrid>
            <InputField
              type="text"
              description="customers.input_vat_number"
              register={register('vatNumber')}
              error={errors.vatNumber}
              optional
            />
            <InputField
              type="text"
              description="customers.input_duns_number"
              register={register('dunsNumber')}
              error={errors.dunsNumber}
              optional
            />
          </TwoColumnGrid>
          <InputField
            type="text"
            description="customers.input_company_email"
            register={register('companyEmailAddress', {
              required: {
                value: true,
                message: intl.formatMessage({ id: 'input.required' })
              },
              pattern: {
                value: regExp.email,
                message: intl.formatMessage({ id: 'input.email_invalid' })
              }
            })}
            error={errors.companyEmailAddress}
          />
          <InputField
            type="text"
            description="customers.input_company_phone"
            register={register('companyPhoneNumber')}
            error={errors.companyPhoneNumber}
            optional
          />
          <InputField
            type="text"
            description="customers.input_address_street"
            register={register('street', {
              required: {
                value: true,
                message: intl.formatMessage({ id: 'input.required' })
              }
            })}
            error={errors.street}
          />
          <TwoColumnGrid>
            <InputField
              type="text"
              description="customers.input_address_postcode"
              register={register('zipCode', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              error={errors.zipCode}
            />
            <InputField
              type="text"
              description="customers.input_address_city"
              register={register('city', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              error={errors.city}
            />
          </TwoColumnGrid>
          <TwoColumnGrid>
            <SelectField
              name="language"
              onSelect={(option) => setValue('language', option.value)}
              placeholder={intl.formatMessage({ id: 'select.placeholder' })}
              label={intl.formatMessage({
                id: 'customers.input_language'
              })}
              register={register('language', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              options={languageOptions}
              error={errors.language}
              enableSearch
            />
            <SelectField
              name="country"
              onSelect={(option) => setValue('country', option.value)}
              placeholder={intl.formatMessage({ id: 'select.placeholder' })}
              label={intl.formatMessage({
                id: 'customers.input_country'
              })}
              register={register('country', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              defaultValue={select.data?.attributes.countryCode}
              options={countryOptions}
              error={errors.country}
              enableSearch
            />
          </TwoColumnGrid>
          <Heading type={HeadingType.h3}>
            <FormattedMessage id="modal.create_customer_primary_contract" />
          </Heading>
          <TwoColumnGrid>
            <InputField
              type="text"
              description="customers.input_firstname"
              register={register('firstName', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              error={errors.firstName}
            />
            <InputField
              type="text"
              description="customers.input_lastname"
              register={register('lastName', {
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'input.required' })
                }
              })}
              error={errors.lastName}
            />
          </TwoColumnGrid>
          <InputField
            type="text"
            description="customers.input_email"
            register={register('emailAddress', {
              required: {
                value: true,
                message: intl.formatMessage({ id: 'input.required' })
              },
              pattern: {
                value: regExp.email,
                message: intl.formatMessage({ id: 'input.email_invalid' })
              }
            })}
            error={errors.emailAddress}
          />
          <InputField
            type="text"
            description="customers.input_phone"
            register={register('phoneNumber')}
            error={errors.phoneNumber}
            optional
          />
          {!duns && (
            <CheckboxContainer error={errors.manuallyVerified}>
              <Checkbox
                id="create-manually-verified"
                register={register('manuallyVerified', {
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: 'input.required' })
                  }
                })}
                error={errors.manuallyVerified}
              />
              <label htmlFor="create-manually-verified">
                <FormattedMessage id="customers.input_manually_verified" />
              </label>
            </CheckboxContainer>
          )}
        </>
      )}
      <ButtonGrid>
        <Button
          onClick={onBack}
          backgroundColor="surface"
          color="onSurface"
          marginRight
        >
          <FormattedMessage id="misc.back" />
        </Button>
        <Button submit>
          <FormattedMessage id="modal.btn_create" />
        </Button>
      </ButtonGrid>
    </form>
  );
};

export default ConfirmProCustomer;
