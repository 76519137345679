import { FC, useEffect, useMemo } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { Container, SupportedFormats, Text } from './styled';
import { showErrorToast } from 'utils/toast';

type Props = {
  onUpload: (files: File[]) => void;
  acceptedFileTypes: string[];
  files: File[];
  multiple?: boolean;
};

export const FileUpload: FC<Props> = ({
  onUpload,
  acceptedFileTypes,
  files,
  multiple = false
}) => {
  const intl = useIntl();

  const {
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: multiple ? 5 : 1,
    maxSize: 40000000,
    accept: acceptedFileTypes.reduce(
      (allFileFormats: Accept, fileType: string) => ({
        ...allFileFormats,
        [fileType]: []
      }),
      {} as Accept
    )
  });

  // Show upload errors
  useEffect(() => {
    fileRejections.forEach(({ errors }) =>
      errors.forEach((item) => showErrorToast(item.message))
    );
  }, [fileRejections]);

  // Handle file upload
  useEffect(() => {
    if (acceptedFiles.length) {
      onUpload(acceptedFiles as File[]);
    }
  }, [onUpload, acceptedFiles]);

  // List files
  const uploadFiles = useMemo(() => {
    if (!files.length) {
      return (
        <Text>
          {intl.formatMessage({
            id: multiple ? 'upload.titleMultipleFiles' : 'upload.titleOneFile'
          })}
        </Text>
      );
    }
    return files.map((file) => {
      const mb = file.size / 1000000;
      const kb = file.size / 1000;

      return (
        <li key={file.name}>
          {file.name} -{' '}
          {Math.round(mb) ? `${mb.toFixed(1)} MB` : `${kb.toFixed(1)} KB`}
        </li>
      );
    });
  }, [files, intl, multiple]);

  return (
    <div>
      <Container
        {...getRootProps({
          $isFocused: isFocused,
          $isDragAccept: isDragAccept,
          $isDragReject: isDragReject
        })}
      >
        <input {...getInputProps()} />
        <svg width="33" height="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.25 24c-2.275 0-4.219-.788-5.831-2.363C.806 20.063 0 18.137 0 15.863c0-1.95.588-3.688 1.762-5.213 1.175-1.525 2.713-2.5 4.613-2.925.625-2.3 1.875-4.162 3.75-5.587C12 .713 14.125 0 16.5 0c2.925 0 5.406 1.019 7.444 3.056C25.98 5.094 27 7.575 27 10.5c1.725.2 3.156.944 4.294 2.231C32.43 14.02 33 15.525 33 17.25c0 1.875-.656 3.469-1.969 4.781C29.72 23.344 28.125 24 26.25 24H18a2.889 2.889 0 0 1-2.119-.881A2.889 2.889 0 0 1 15 21v-7.725L12.6 15.6l-2.1-2.1 6-6 6 6-2.1 2.1-2.4-2.325V21h8.25c1.05 0 1.938-.363 2.663-1.087C29.637 19.187 30 18.3 30 17.25c0-1.05-.363-1.938-1.087-2.662-.726-.725-1.613-1.088-2.663-1.088H24v-3c0-2.075-.731-3.844-2.194-5.306C20.344 3.73 18.575 3 16.5 3s-3.844.731-5.306 2.194C9.73 6.656 9 8.425 9 10.5h-.75c-1.45 0-2.688.512-3.713 1.537C3.513 13.063 3 14.3 3 15.75c0 1.45.513 2.688 1.537 3.712C5.563 20.488 6.8 21 8.25 21H12v3H8.25Z"
            fill="#3D3D3C"
            fillRule="nonzero"
          />
        </svg>
        {uploadFiles}
      </Container>
      <SupportedFormats>
        {intl.formatMessage(
          { id: 'upload.supported_formats' },
          {
            formats: acceptedFileTypes.map((fileType) => fileType).join(' ')
          }
        )}
      </SupportedFormats>
    </div>
  );
};
