import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { authSelector, localizationSelector, logout, setLocale } from 'store';
import {
  moreMenu,
  serviceMenu,
  userMenu,
  userMenuDropdown,
  userStatisticsMenu
} from 'utils/constants';
import { getInitials } from 'utils/string';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useScreenSize } from 'hooks/useScreenSize';
import { useAccess } from 'hooks/useAccess';
import { Language, MenuItem } from 'models';
import { breakpoints } from 'styles';

import MobileMenu from 'components/UI/MobileMenu';
import NavDropdownButton from 'components/Layout/NavBar/NavDropdownButton';

import NavBar from './NavBar';
import RolesModal from './RolesModal';

import { Main, Container, Content } from './styled';

const Layout: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { width } = useScreenSize();
  const { pathname } = useLocation();
  const { locale, languages } = useAppSelector(localizationSelector);
  const {
    session: { scpUser }
  } = useAppSelector(authSelector);
  const scpUserAccess = useAccess();

  // State
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [openRolesModal, setOpenRolesModal] = useState<boolean>(false);

  // Scroll to top
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  // Render menu conditionally based on accesses
  const menu = useMemo(() => {
    const {
      azureAccess,
      diamAccess,
      diamStatisticsAccess,
      diamBatchJobAccess
    } = scpUserAccess;
    const menu: MenuItem[] = [];

    // Other menu
    if (azureAccess) {
      menu.push(...serviceMenu);
    }

    // Users menu
    if (diamAccess && diamStatisticsAccess) {
      menu.push(userMenuDropdown);
    }

    if (diamAccess && !diamStatisticsAccess) {
      menu.push(userMenu);
    }

    if (!diamAccess && diamStatisticsAccess) {
      menu.push(userStatisticsMenu);
    }

    if (diamAccess && diamBatchJobAccess) {
      menu.push(moreMenu);
    }

    return menu;
  }, [scpUserAccess]);

  // Dropdown menu
  const dropdownMenu = useMemo(() => {
    return [
      {
        id: 1,
        text: `${intl.formatMessage({ id: 'header.roles' })}`,
        onClick: () => setOpenRolesModal(true)
      },
      {
        id: 2,
        text: `${intl.formatMessage({ id: 'header.logout' })}`,
        onClick: () => dispatch(logout())
      }
    ];
  }, [intl, setOpenRolesModal, dispatch]);

  // Handle name on desktop / mobile
  const fullName = useMemo(() => {
    if (!scpUser) {
      return '';
    }
    if (width <= breakpoints.s) {
      return getInitials(scpUser.displayName);
    }
    return scpUser.displayName;
  }, [scpUser, width]);

  // Buttons
  const actionButtons = useMemo(() => {
    return (
      <Fragment>
        <NavDropdownButton
          text={locale.toUpperCase()}
          menu={languages.map((language: Language, i: number) => ({
            id: i + 1,
            text: language.toUpperCase(),
            onClick: () => dispatch(setLocale(language))
          }))}
        />
        <NavDropdownButton text={fullName} menu={dropdownMenu} />
      </Fragment>
    );
  }, [dispatch, languages, locale, fullName, dropdownMenu]);

  // Close modal
  const onRolesModalClose = useCallback(
    () => setOpenRolesModal(false),
    [setOpenRolesModal]
  );

  return (
    <Fragment>
      <NavBar
        leftMenu={menu}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      >
        {actionButtons}
      </NavBar>
      <Container>
        <Main>
          <Content>
            <Outlet />
          </Content>
        </Main>
        <MobileMenu
          menu={menu}
          open={mobileMenuOpen}
          setOpen={setMobileMenuOpen}
        />
      </Container>
      <RolesModal
        user={scpUser}
        open={openRolesModal}
        onClose={onRolesModalClose}
      />
    </Fragment>
  );
};

export default Layout;
