import { Variants } from 'framer-motion';
import { JsonData, SCPRoles, MenuItem, ServicePlanGroup } from 'models';
import * as routes from 'router/Routes';

export const regExp = {
  email:
    /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
  allCapLetters: /^[A-Z]+$/,
  uuid: /^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$/i,
  nonAlphanumeric: /[^a-zA-Z0-9]/g
};

const baseTransition = {
  visibility: {
    delay: 0.2
  },
  height: {
    duration: 0.2
  }
};

export const foldableVariants: Variants = {
  open: {
    height: 'auto',
    visibility: 'visible',
    opacity: 1,
    transition: {
      ...baseTransition,
      opacity: {
        delay: 0.2,
        duration: 0.1
      }
    }
  },
  closed: {
    height: 0,
    visibility: 'hidden',
    opacity: 0,
    transition: {
      ...baseTransition,
      opacity: {
        duration: 0.2
      }
    }
  }
};

export const emptyRoles: SCPRoles = {
  azure: [],
  diam: {
    isGlobalAdmin: false,
    isGlobalReadonlyAdmin: false,
    isAdmin: false,
    isReadOnlyAdmin: false,
    isAnyAdmin: false,
    isBatchApiAdmin: false,
    isBatchApiUser: false,
    canViewStatistics: false,
    systemGroups: [],
    adminGroups: [],
    readOnlyGroups: []
  }
};

export const diamRolePermissions: JsonData = {
  isGlobalAdmin: 'GlobalAdmin',
  isGlobalReadonlyAdmin: 'GlobalReadonlyAdmin',
  isAdmin: 'Admin',
  isReadOnlyAdmin: 'ReadonlyAdmin',
  canViewStatistics: 'ViewStatistics'
};

export const serviceMenu: MenuItem[] = [
  {
    id: 'customers',
    intl: 'sidebar.customers',
    link: routes.CUSTOMERS
  },
  {
    id: 'products',
    intl: 'sidebar.products',
    link: routes.PRODUCTS
  },
  {
    id: 'service-contracts',
    intl: 'sidebar.service_contracts',
    link: routes.SERVICE_CONTRACTS
  }
];

export const userMenu: MenuItem = {
  id: 'users',
  intl: 'sidebar.users',
  link: routes.USERS
};

export const userStatisticsMenu: MenuItem = {
  id: 'users',
  intl: 'sidebar.users',
  link: routes.USER_STATISTICS
};

export const userMenuDropdown: MenuItem = {
  id: 'users',
  intl: 'sidebar.users',
  link: routes.USERS,
  menu: [
    {
      id: 'user-search',
      intl: 'sidebar.user_search',
      link: routes.USERS
    },
    {
      id: 'user-statistics',
      intl: 'sidebar.user_statistics',
      link: routes.USER_STATISTICS
    }
  ]
};

export const moreMenu: MenuItem = {
  id: 'more',
  intl: 'sidebar.more',
  link: '#',
  menu: [
    {
      id: 'batch-jobs',
      intl: 'sidebar.batch_jobs',
      link: routes.BATCH_JOBS
    }
  ]
};

export const defaultOnboardingSource = 'PRI';

export const userStatisticsNumberOfBars = 8;

export const userStatisticsTickCharacterLimit = 12;

export const userEmailBouncesDiagnosticInfoCharacterLimit = 150;

export const servicePlanSortGroups: ServicePlanGroup[] = [
  {
    start: 1,
    end: 999,
    title: 'Function groups, general'
  },
  {
    start: 1000,
    end: 1999,
    title: 'Spare parts & Service material'
  },
  {
    start: 2000,
    end: 2999,
    title: 'Combustion engine'
  },
  {
    start: 3000,
    end: 3999,
    title: 'Electric engine'
  },
  {
    start: 4000,
    end: 4999,
    title: 'Electric system'
  },
  {
    start: 5000,
    end: 5999,
    title: 'Power transmission'
  },
  {
    start: 6000,
    end: 6999,
    title: 'Brake system'
  },
  {
    start: 7000,
    end: 7999,
    title: 'Wheel suspension & Steering'
  },
  {
    start: 8000,
    end: 8999,
    title: 'Frame/Chassis'
  },
  {
    start: 9000,
    end: 9999,
    title: 'Body, Interior and Exterior'
  },
  {
    start: 15000,
    end: 15999,
    title: 'Other function systems'
  },
  {
    start: 25000,
    end: 25999,
    title: 'Cutting deck and cutting module'
  },
  {
    start: 26000,
    end: 26999,
    title: 'Sawing, cutting, trimming, drilling equipment'
  },
  {
    start: 27000,
    end: 27999,
    title: 'Concrete, compaction, surface preparation'
  },
  {
    start: 28000,
    end: 28999,
    title: 'Demolition equipment'
  },
  {
    start: 35000,
    end: 35999,
    title: 'Other equipment'
  },
  {
    start: 40000,
    end: 40999,
    title: 'Attachments'
  },
  {
    start: 45000,
    end: 45999,
    title: 'Accessories'
  },
  {
    start: 50000,
    end: 50999,
    title: 'Firmware, complete product'
  },
  {
    start: 55000,
    end: 55999,
    title: 'Data'
  }
];

export const brands = {
  husqvarna: 'Husqvarna',
  gardena: 'GARDENA',
  construction: 'Construction'
};
