import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { TagGrid } from 'styles';
import { useDiamAccess } from 'hooks/useDiamAccess';
import { User } from 'models';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import InfoItem from 'components/UI/InfoItem';
import Tag from 'components/UI/Tag';
import DropdownMenu from 'components/UI/DropdownMenu';
import IconButton from 'components/UI/IconButton';
import Icon, { IconType } from 'components/UI/Icon';

import ConfirmUserDeletionModal from '../modals/ConfirmUserDeletionModal';
import EditUserPermissionsModal from '../modals/EditUserPermissionsModal';

import { ContentContainer } from './styled';

type Props = {
  user: User;
  fetchData: (refetch: boolean) => void;
  fetchAuditLogs: () => void;
};

const UserPermissionsCard: FC<Props> = ({
  user,
  fetchData,
  fetchAuditLogs
}) => {
  const intl = useIntl();
  const { isGlobalOrGroupAdmin } = useDiamAccess(user);
  const { detailedGroups, roles } = user.attributes;

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [confirmDeleteUserModalOpen, setConfirmDeleteUserModalOpen] =
    useState<boolean>(false);

  // Actions
  const onClose = useCallback(() => setModalOpen(false), []);
  const onOpenConfirmDeleteModal = useCallback(
    () => setConfirmDeleteUserModalOpen(true),
    []
  );
  const onCloseConfirmDeleteModal = useCallback(
    () => setConfirmDeleteUserModalOpen(false),
    []
  );

  // Groups
  const groupTags = useMemo(
    () => (
      <TagGrid>
        {detailedGroups.length
          ? detailedGroups.map((group) => (
              <Tag key={group.id}>{group.name || group.id}</Tag>
            ))
          : '-'}
      </TagGrid>
    ),
    [detailedGroups]
  );

  // Roles
  const roleTags = useMemo(
    () => (
      <TagGrid>
        {roles.length ? roles.map((role) => <Tag key={role}>{role}</Tag>) : '-'}
      </TagGrid>
    ),
    [roles]
  );

  // Menu
  const menu = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: 'user_details.btn_edit_permissions'
        }),
        onClick: () => setModalOpen(true)
      }
    ];
  }, [intl]);

  return (
    <Card>
      <CardHeader titleIntl="user_details.permissions">
        {isGlobalOrGroupAdmin && (
          <DropdownMenu menu={menu}>
            <IconButton>
              <Icon type={IconType.More} themeType="grey6" />
            </IconButton>
          </DropdownMenu>
        )}
      </CardHeader>
      <ContentContainer>
        <InfoItem
          title={intl.formatMessage({ id: 'user_details.groups' })}
          value={groupTags}
        />
        <InfoItem
          title={intl.formatMessage({ id: 'user_details.roles' })}
          value={roleTags}
        />
      </ContentContainer>
      <EditUserPermissionsModal
        userId={user.id}
        userRoles={roles}
        userGroups={detailedGroups}
        confirmDeleteUser={onOpenConfirmDeleteModal}
        fetchData={fetchData}
        fetchAuditLogs={fetchAuditLogs}
        onClose={onClose}
        open={modalOpen}
      />
      <ConfirmUserDeletionModal
        userGroups={detailedGroups}
        onClose={onCloseConfirmDeleteModal}
        open={confirmDeleteUserModalOpen}
        user={user}
      />
    </Card>
  );
};

export default UserPermissionsCard;
