import { useMemo } from 'react';

import { useAppSelector } from 'hooks/redux';
import { DIAMUserAccess, User } from 'models';
import { authSelector } from 'store';
import {
  getDiamSystemGroups,
  hasDiamBatchJobAccess,
  hasDiamBatchJobAdminAccess,
  isAnyDiamGlobalAdmin,
  isAnyReadonlyAdmin,
  isDiamGlobalAdmin,
  isDiamGlobalAdminOrGroupAdmin
} from 'utils/roles';

export const useDiamAccess = (user?: User): DIAMUserAccess => {
  const { session } = useAppSelector(authSelector);

  return useMemo(() => {
    const { scpUser } = session;
    if (!scpUser) {
      return {
        isGlobalAdmin: false,
        isGlobalOrGroupAdmin: false,
        isAnyGlobalAdmin: false,
        isAnyReadonlyAdmin: false,
        isBatchApiAdmin: false,
        isBatchApiUser: false,
        groups: []
      };
    }
    return {
      isGlobalAdmin: isDiamGlobalAdmin(scpUser),
      isGlobalOrGroupAdmin: user
        ? isDiamGlobalAdminOrGroupAdmin(scpUser, user)
        : isDiamGlobalAdmin(scpUser),
      isAnyGlobalAdmin: isAnyDiamGlobalAdmin(scpUser),
      isAnyReadonlyAdmin: isAnyReadonlyAdmin(scpUser),
      isBatchApiAdmin: hasDiamBatchJobAdminAccess(scpUser),
      isBatchApiUser: hasDiamBatchJobAccess(scpUser),
      groups: getDiamSystemGroups(scpUser)
    };
  }, [session, user]);
};
