import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  createBatchJob,
  createBatchJobSelector,
  resetCreateBatchJob
} from 'store';
import { getBatchJobExample } from 'services/batchJobService/batchJobService';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { downloadFile } from 'utils/exporting';

import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import InputField from 'components/UI/InputField';
import { SelectField, OptionType } from 'components/UI/SelectField';
import { FileUpload } from 'components/FileUpload';
import Loader from 'components/UI/Loader';

import { ButtonGrid, DownloadLink } from './styled';

type FormValues = {
  name: string;
  type: string;
  dryRun: string;
  file: File | null;
};

type Props = {
  open: boolean;
  onCreate: () => void;
  onClose: () => void;
};

export const CreateBatchJobModal: FC<Props> = ({ open, onCreate, onClose }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, error } = useAppSelector(
    createBatchJobSelector
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      type: 'REGISTER_PRODUCTS',
      dryRun: 'true',
      file: undefined,
    }
  });

  // Watch
  const [type, file] = watch(['type', 'file']);

  // Reset
  useEffect(() => {
    return () => {
      dispatch(resetCreateBatchJob());
    };
  }, [dispatch]);

  // Success
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetCreateBatchJob());
      showSuccessToast(intl.formatMessage({ id: 'batch_jobs.create_success' }));
      onCreate();
      onClose();
    }
    if (error) {
      showErrorToast(error.title);
    }
  }, [dispatch, intl, isSuccess, error, onClose, onCreate]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      dispatch(
        createBatchJob({
          name: values.name,
          type: values.type,
          dryRun: values.dryRun,
          file: values.file
        })
      );
    },
    [dispatch]
  );

  // Select
  const onSelect = useCallback(
    (name: keyof FormValues) => (option: OptionType) => {
      setValue(name, option.value);
    },
    [setValue]
  );

  // Upload file
  const onUpload = useCallback(
    (files: File[]) => {
      setValue('file', files[0]);
    },
    [setValue]
  );

  // Run type options
  const runTypeOptions = useMemo(
    () => [
      { key: 'true', label: 'Test', value: 'true' },
      { key: 'false', label: 'Live', value: 'false' }
    ],
    []
  );

  // Download example
  const onDownloadExample = useCallback(async () => {
    try {
      const csv = await getBatchJobExample();
      downloadFile(csv, 'batch_job_example');
    } catch {
      showErrorToast(
        intl.formatMessage({ id: 'batch_jobs.create_example_file_error' })
      );
    }
  }, [intl]);

  return (
    <EditModal title="batch_jobs.create_title" open={open} close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? (
          <Loader padding center />
        ) : (
          <>
            <InputField
              description="batch_jobs.input_name"
              register={register('name', {
                required: {
                  value: true,
                  message: 'input.required'
                }
              })}
              error={errors.name}
            />
            <SelectField
              name="dryRun"
              register={register('dryRun')}
              onSelect={onSelect('dryRun')}
              placeholder={intl.formatMessage({ id: 'select.placeholder' })}
              label={intl.formatMessage({
                id: 'batch_jobs.input_run_type'
              })}
              options={runTypeOptions}
              value={type}
              error={errors.dryRun}
            />
            <FileUpload
              onUpload={onUpload}
              acceptedFileTypes={['text/csv']}
              files={file ? [file] : []}
            />
            <DownloadLink onClick={onDownloadExample}>
              <FormattedMessage id="batch_jobs.create_example_file" />
            </DownloadLink>
          </>
        )}
        <ButtonGrid>
          <Button
            onClick={onClose}
            backgroundColor="transparent"
            color="primary"
            marginRight
          >
            <FormattedMessage id="modal.btn_cancel" />
          </Button>
          <Button disabled={!isDirty || isLoading} submit>
            <FormattedMessage id="modal.btn_create" />
          </Button>
        </ButtonGrid>
      </form>
    </EditModal>
  );
};
