import styled from 'styled-components';
import { breakpoints } from 'styles';

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 16px;
  margin: 0;
`;

export const IconText = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.grey5};
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  > svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: -12px;
  top: -8px;

  > svg {
    height: 12px;
    width: 12px;
  }
`;

export const DetailGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
