import styled from 'styled-components';

export const Registrations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const LoadMoreGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

export const LoadMoreText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.grey5};
`;

export const RegisterButtonInner = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
`;
