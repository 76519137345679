import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { showInfoToast } from 'utils/toast';
import { ProductFilterObject, RegisterFormFields } from 'models';
import {
  productRegistrationSelector,
  searchProductsToRegister,
  registerProducts,
  resetProductRegistration,
  productSearchSuccess,
  metaSelector
} from 'store';

import InfoItem from 'components/UI/InfoItem';
import Heading, { Type } from 'components/UI/Heading';
import EditModal from 'components/UI/EditModal';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';

import { ButtonGrid } from 'styles';

import SearchProductsForm from './SearchProductsForm';
import RegistrationForm from './RegistrationForm';

import {
  Results,
  Grid,
  NotFoundCard,
  ProductCard,
  ProductContent,
  ProductImage,
  NoProductImage
} from './styled';

type Props = {
  onClose: () => void;
  open: boolean;
  customerId: string;
  onUpdate: () => void;
};

const RegisterProductModal: FC<Props> = ({
  onUpdate,
  onClose,
  open,
  customerId
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const meta = useAppSelector(metaSelector);
  const { data, params, isSuccess, isLoading, error } = useAppSelector(
    productRegistrationSelector
  );

  // Update on success
  useEffect(() => {
    if (isSuccess) {
      onUpdate();
      onClose();
    }
  }, [onUpdate, onClose, isSuccess]);

  // Reset state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetProductRegistration());
    };
  }, [dispatch]);

  // On register
  const onRegister = useCallback(
    (values: RegisterFormFields) => {
      if (data) {
        dispatch(
          registerProducts(
            {
              product: {
                pnc: data.pnc,
                serialNumber: data.serialNumber,
                createInIpr: data.createInIpr
              },
              dealer: {
                companyCode: values.companyCode,
                customerNumber: values.customerNumber
              },
              soldDate: new Date(values.soldDate).valueOf(),
              customerId
            },
            intl
          )
        );
      }
    },
    [dispatch, customerId, data, intl]
  );

  // On search
  const onSearch = useCallback(
    (values: ProductFilterObject) => {
      dispatch(searchProductsToRegister(values));
    },
    [dispatch]
  );

  // Reset search
  const onReset = useCallback(() => {
    dispatch(resetProductRegistration());
  }, [dispatch]);

  // Create product to register
  const onCreateProduct = useCallback(
    (filter: ProductFilterObject) => () => {
      if (
        data?.pnc === filter.pnc &&
        data?.serialNumber === filter.serialNumber
      ) {
        showInfoToast(
          intl.formatMessage({
            id: 'modal.register_products_already_selected'
          })
        );
        return;
      }

      dispatch(
        productSearchSuccess({
          title: intl.formatMessage({
            id: 'modal.register_products_new_title'
          }),
          pnc: filter.pnc,
          serialNumber: filter.serialNumber,
          husqvarnaId: filter.husqvarnaId,
          createInIpr: true
        })
      );
    },
    [dispatch, data, intl]
  );

  // Render search results
  const searchResults = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }

    // Create not found product, skip for husqvarnaId
    if (error?.status === 404 && params && !params.husqvarnaId) {
      const { pnc, serialNumber } = params;
      return (
        <NotFoundCard>
          <Heading type={Type.h3}>
            <FormattedMessage id="product_details.not_found" />
          </Heading>
          <p>
            <FormattedMessage
              id="modal.register_products_not_found_description"
              values={{
                values: (
                  <b>
                    {`${intl.formatMessage({ id: 'product_details.pnc' })}: ${pnc}, ${intl.formatMessage({ id: 'product_details.serial' })}: ${serialNumber}`}
                  </b>
                )
              }}
            />
          </p>
          <ButtonGrid>
            <Button
              color="onSurface"
              backgroundColor="surface"
              size="small"
              marginRight
              onClick={onReset}
            >
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button size="small" onClick={onCreateProduct(params)}>
              <FormattedMessage id="button.create" />
            </Button>
          </ButtonGrid>
        </NotFoundCard>
      );
    }

    // Empty state
    if (!data && !params) {
      return (
        <EmptyState icon={IconType.Search}>
          <FormattedMessage id="modal.register_products_empty_state" />
        </EmptyState>
      );
    }

    // Product not found
    if (!data) {
      return (
        <EmptyState icon={IconType.Search}>
          <FormattedMessage id="modal.register_products_not_found" />
        </EmptyState>
      );
    }

    return (
      <ProductCard>
        {data.imageUrl ? (
          <ProductImage src={data.imageUrl} alt={data.imageId} />
        ) : (
          <NoProductImage>
            <Icon type={IconType.Image} size="extraLarge" themeType="grey4" />
          </NoProductImage>
        )}
        <ProductContent>
          <Heading type={Type.h3}>{data.title}</Heading>
          <Grid>
            <InfoItem
              title={intl.formatMessage({
                id: 'customers.product_table_pnc'
              })}
              value={data.pnc}
            />
            <InfoItem
              title={intl.formatMessage({
                id: 'customers.product_table_serial'
              })}
              value={data.serialNumber}
            />
          </Grid>
        </ProductContent>
      </ProductCard>
    );
  }, [intl, data, params, isLoading, error, onCreateProduct, onReset]);

  if (meta.isLoading) {
    return <Loader center padding />;
  }

  return (
    <EditModal
      title="modal.register_products_title"
      open={open}
      close={onClose}
    >
      <div>
        <Heading type={Type.h3} uppercase>
          <FormattedMessage id="modal.register_products_search_title" />
        </Heading>
        <SearchProductsForm onSearch={onSearch} onReset={onReset} />
        <Results>{searchResults}</Results>
        <RegistrationForm
          onClose={onClose}
          onRegister={onRegister}
          salesCompanyCodes={meta.data.salesCompanyCodes.write}
          disabled={!data}
        />
      </div>
    </EditModal>
  );
};

export default RegisterProductModal;
