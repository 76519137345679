import { FC, useEffect, useCallback, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  customerDetailsSelector,
  fetchCustomer,
  fetchAcceptance,
  fetchCustomerConsent,
  consentSelector,
  acceptanceSelector,
  resetAcceptanceState,
  resetConsentState,
  resetCustomerDetailsState,
  removeAcceptance,
  deleteCustomerProductRegistrations,
  auditLogsSelector,
  fetchAuditLogs,
  resetAuditLogsState,
  requestChangeEmail,
  updateCustomerData,
  deleteCustomer,
  removeWarrantyConditionByIprId,
  loadMoreCustomerProductRegistrations,
  fetchCustomerProductRegistrations
} from 'store';

import { AuditLogAttribute, CustomerUpdate } from 'models';

import { showErrorToast, showSuccessToast } from 'utils/toast';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useValidateAzureEditCustomer } from 'hooks/useValidateAzureEditCustomer';

import Loader from 'components/UI/Loader';
import CustomerNameCard from 'components/customers/CustomerNameCard';
import ConsentCard from 'components/customers/ConsentCard';
import CustomerAcceptanceCard from 'components/customers/CustomerAcceptanceCard';
import CustomerAddressCard from 'components/customers/CustomerAddressCard';
import CustomerProductCard from 'components/customers/CustomerProductCard';
import AuditLogCard from 'components/auditlog/AuditLogCard';
import EmptyState from 'components/UI/EmptyState';
import NoAccessState from 'components/UI/NoAccessState';
import { DeleteRegistrationsProps } from 'components/products/EditProductModal';
import { IconType } from 'components/UI/Icon';

import { Grid, Column } from 'styles';

const CustomerDetailsPage: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const {
    data,
    error,
    isLoading,
    productRegistrations,
    deleteRegistrations,
    changeEmail,
    update,
    confirmDeleteCustomer
  } = useAppSelector(customerDetailsSelector);
  const auditLogs = useAppSelector(auditLogsSelector);
  const acceptance = useAppSelector(acceptanceSelector);
  const consent = useAppSelector(consentSelector);

  // Hooks
  const canEdit = useValidateAzureEditCustomer();

  // Fetch audit logs
  const fetchAuditLogsByCustomerId = useCallback(() => {
    if (customerId) {
      dispatch(
        fetchAuditLogs([
          {
            attribute: AuditLogAttribute.CustomerId,
            value: customerId
          }
        ])
      );
    }
  }, [dispatch, customerId]);

  // Fetch product registration data
  const fetchProductRegistrationData = useCallback(() => {
    if (customerId) {
      dispatch(fetchCustomerProductRegistrations(customerId));
    }
    fetchAuditLogsByCustomerId();
  }, [dispatch, fetchAuditLogsByCustomerId, customerId]);

  // Change email
  const onChangeEmail = useCallback(
    (newEmail: string, oldEmail: string) => {
      if (customerId) {
        dispatch(requestChangeEmail(customerId, { newEmail, oldEmail }));
      }
    },
    [dispatch, customerId]
  );

  // Update customer
  const onCustomerUpdate = useCallback(
    (newData: CustomerUpdate) => {
      if (customerId) {
        dispatch(updateCustomerData(customerId, newData));
      }
    },
    [dispatch, customerId]
  );

  // Confirm Delete Account
  const onConfirmDeleteCustomer = useCallback(
    (deleteReason: string) => {
      if (customerId) {
        dispatch(deleteCustomer(customerId, deleteReason));
      }
    },
    [dispatch, customerId]
  );

  // Delete acceptance
  const onDeleteAcceptance = useCallback(
    (acceptanceId: string) => {
      if (customerId) {
        dispatch(removeAcceptance(customerId, acceptanceId));
      }
    },
    [dispatch, customerId]
  );

  // Delete relation
  const onDeleteRegistrations = useCallback(
    (props: DeleteRegistrationsProps) => {
      const { registrationIds, iprId, removeWarrantyCondition } = props;

      if (customerId) {
        dispatch(
          deleteCustomerProductRegistrations(customerId, iprId, registrationIds)
        );
      }

      if (removeWarrantyCondition) {
        dispatch(removeWarrantyConditionByIprId(iprId));
      }
    },
    [dispatch, customerId]
  );

  // Load more
  const onLoadMoreProductRegistrations = useCallback(() => {
    if (customerId) {
      dispatch(
        loadMoreCustomerProductRegistrations(
          customerId,
          productRegistrations.pagination
        )
      );
    }
  }, [dispatch, customerId, productRegistrations]);

  // Mount fetch customer
  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomer(customerId));
    }
    fetchProductRegistrationData();
    return () => {
      dispatch(resetCustomerDetailsState());
      dispatch(resetAcceptanceState());
      dispatch(resetConsentState());
      dispatch(resetAuditLogsState());
    };
  }, [dispatch, fetchProductRegistrationData, customerId]);

  // Mount fetch consents and acceptances if customer has a DIAM account and customerId
  useEffect(() => {
    if (customerId && data?.attributes.userAccount) {
      dispatch(fetchCustomerConsent(customerId));
      dispatch(fetchAcceptance(customerId));
    }
  }, [dispatch, data, customerId]);

  // Update acceptance
  useEffect(() => {
    if (customerId && acceptance.deleteStamp) {
      dispatch(fetchAcceptance(customerId));
    }
    if (customerId && acceptance.error) {
      dispatch(fetchAcceptance(customerId));
    }
  }, [dispatch, acceptance.deleteStamp, acceptance.error, customerId]);

  // Update customer & products on delete
  useEffect(() => {
    if (deleteRegistrations.timeStamp) {
      showSuccessToast(
        intl.formatMessage({ id: 'modal.remove_product_success' })
      );
      fetchProductRegistrationData();
    }
    if (deleteRegistrations.error) {
      showErrorToast(
        intl.formatMessage({ id: 'modal.remove_product_failure' })
      );
      fetchProductRegistrationData();
    }
  }, [
    fetchProductRegistrationData,
    deleteRegistrations.timeStamp,
    deleteRegistrations.error,
    intl
  ]);

  // Change email success and error
  useEffect(() => {
    if (changeEmail.isSuccess) {
      showSuccessToast(
        intl.formatMessage(
          { id: 'modal.change_email_success' },
          {
            email: changeEmail.newEmail
          }
        )
      );
      fetchAuditLogsByCustomerId();
    }
  }, [fetchAuditLogsByCustomerId, intl, changeEmail]);

  // Update customer success and error
  useEffect(() => {
    if (update.isSuccess) {
      showSuccessToast(
        intl.formatMessage({ id: 'modal.update_customer_success' })
      );
      fetchAuditLogsByCustomerId();
    }
  }, [fetchAuditLogsByCustomerId, intl, update]);

  // Loading
  if (isLoading) {
    return <Loader center padding />;
  }

  // No access
  if (!data && error?.status === 403) {
    return <NoAccessState />;
  }

  // Not found
  if (!data) {
    return (
      <EmptyState icon={IconType.Customer} padding includeParams>
        <FormattedMessage id="customer_details.not_found" />
      </EmptyState>
    );
  }

  return (
    <Fragment>
      <CustomerNameCard
        customer={data}
        onChangeEmail={onChangeEmail}
        onCustomerUpdate={onCustomerUpdate}
        onConfirmDeleteCustomer={onConfirmDeleteCustomer}
        deleteCustomer={confirmDeleteCustomer}
        changeEmail={changeEmail}
        canEdit={canEdit}
        update={update}
      />
      <Grid>
        <Column $col={1} $marginRight>
          <CustomerAddressCard
            customer={data.attributes}
            onCustomerUpdate={onCustomerUpdate}
            update={update}
            canEdit={canEdit}
          />
        </Column>
        <Column $col={2}>
          <ConsentCard isLoading={consent.isLoading} data={consent.data} />
          <CustomerAcceptanceCard
            isLoading={acceptance.isLoading}
            data={acceptance.data}
            deleteAcceptance={onDeleteAcceptance}
            canEdit={canEdit}
          />
        </Column>
      </Grid>
      <Grid>
        <Column>
          <CustomerProductCard
            customer={data.attributes}
            productRegistrations={productRegistrations.data}
            pagination={productRegistrations.pagination}
            isLoading={productRegistrations.isLoading}
            onLoadMore={onLoadMoreProductRegistrations}
            onDeleteRegistrations={onDeleteRegistrations}
            updateData={fetchProductRegistrationData}
            canEdit={canEdit}
          />
          <AuditLogCard isLoading={auditLogs.isLoading} data={auditLogs.data} />
        </Column>
      </Grid>
    </Fragment>
  );
};

export default CustomerDetailsPage;
