import { FC, useCallback, useMemo, useState } from 'react';

import { ServiceRecord, Workshop } from 'models';
import { formatDate } from 'utils/date';

import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';
import ServiceRecordModal from 'components/servicerecords/ServiceRecordModal';
import Loader from 'components/UI/Loader';

const headerCells: HeaderCell[] = [
  {
    intl: 'service_book.table_title',
    sortable: true
  },
  {
    intl: 'service_book.table_type',
    sortable: true
  },
  {
    intl: 'service_book.table_date',
    sortable: true
  },
  {
    intl: 'service_book.table_done_by',
    sortable: true
  }
];

type Modal = {
  isOpen: boolean;
  data?: ServiceRecord;
};

type Props = {
  data: ServiceRecord[];
  isLoading: boolean;
  descriptionIntl?: string;
};

const ServiceRecordCard: FC<Props> = ({ data, isLoading, descriptionIntl }) => {
  // State
  const [modal, setModal] = useState<Modal>({ isOpen: false });

  // Callbacks
  const getDoneBy = useCallback((type?: string, workshop?: Workshop) => {
    if (workshop?.workshopName && type === 'Workshop') {
      return workshop.workshopName;
    }
    if (type) {
      return type;
    }
    return null;
  }, []);

  const onOpenModal = useCallback(
    (id: string) => {
      setModal({
        isOpen: true,
        data: data.find((item) => item.id === id)
      });
    },
    [data]
  );
  const onCloseModal = useCallback(
    () => setModal({ isOpen: false, data: undefined }),
    []
  );

  // Render table
  const table = useMemo(() => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <SortableTable
        headerCells={headerCells}
        bodyRows={data.map(({ id, attributes }) => ({
          title: !attributes.title ? '-' : attributes.title,
          type: attributes.type ?? '-',
          date: attributes.recordDate
            ? formatDate(attributes.recordDate, 'yyyy-MM-dd')
            : '–',
          workshop:
            getDoneBy(attributes.performedByType, attributes.workshop) ?? '–',
          link: id
        }))}
        sortIndex={2}
        emptyTextId="service_book.table_empty"
        onClick={onOpenModal}
      />
    );
  }, [data, isLoading, onOpenModal, getDoneBy]);

  return (
    <Card>
      <CardHeader
        titleIntl="service_book.title"
        descriptionIntl={descriptionIntl}
      />
      {table}
      <ServiceRecordModal
        data={modal.data}
        isOpen={modal.isOpen}
        onClose={onCloseModal}
        getDoneBy={getDoneBy}
      />
    </Card>
  );
};

export default ServiceRecordCard;
