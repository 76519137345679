import { FC } from 'react';

import { BatchJobItem } from 'models';

import Tag from 'components/UI/Tag';
import Card from 'components/UI/Card';
import CardHeader from 'components/UI/Card/CardHeader';
import SortableTable, { HeaderCell } from 'components/UI/Table/SortableTable';

import { DataArea } from './styled';

const headerCells: HeaderCell[] = [
  {
    intl: 'batch_job_details.table_header_job_item_line_no',
    sortable: true
  },
  {
    intl: 'batch_job_details.table_header_job_item_status',
    sortable: true
  },
  {
    intl: 'batch_job_details.table_header_job_item_data',
    sortable: true
  },
  {
    intl: 'batch_job_details.table_header_job_item_errors',
    sortable: true
  }
];

type Props = {
  data: BatchJobItem[];
};

export const BatchJobItemTable: FC<Props> = ({ data }) => {
  return (
    <Card>
      <CardHeader titleIntl="batch_job_details.job_item_title" />
      <SortableTable
        headerCells={headerCells}
        emptyTextId="batch_job_details.job_item_empty"
        bodyRows={data.map((job) => ({
          lineNo: job.attributes.lineNumber || '–',
          status: (
            <Tag background={job.attributes.success ? 'success' : 'grey3'} color={job.attributes.success ? 'onPrimary' : 'grey6'}>
              {job.attributes.success ? 'Success' : 'In Progress'}
            </Tag>
          ),
          data: job.attributes.data ? (
            <DataArea>{JSON.stringify(job.attributes.data, null, 2)}</DataArea>
          ) : (
            '–'
          ),
          errors:
            job.attributes.errors?.map((error) => error.title).join(', ') || '–'
        }))}
      />
    </Card>
  );
};
