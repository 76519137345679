import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const ModalBackground = styled(motion.div)`
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => `${theme.colors.grey9}77`};
  z-index: 99;
  overflow-y: auto;
`;

export const ModalContainer = styled(motion.div)`
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 600px;
`;

export const ModalBox = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 24px;
  margin: 15% 16px;

  @media (max-width: ${breakpoints.s}px) {
    margin: 16px;
  }
`;
