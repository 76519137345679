import {
  LanguageValuePair,
  PerformedTask,
  ServiceInterval,
  ServicePlanGroupIntervals,
  ServicePlanPart,
  ServicePlanFlatInterval,
  ServiceRecord,
  ServiceRecordJsonData,
  ServiceablePart,
  ProductTreeAttributes,
  ProductAttributes,
  DealerAttributes,
  WarrantyAttributes,
  DealerInfo,
  CustomerProductRegistrationResponse
} from 'models';
import { IntlShape } from 'react-intl';
import { filterDuplicates, updateArrayItem } from 'utils/array';
import { servicePlanSortGroups } from 'utils/constants';
import { capitalizeFirstChar } from 'utils/string';
import { showSuccessToast, showWarningToast } from 'utils/toast';

export function getProductText(product: ProductAttributes): string {
  if (product.brand && product.modelName) {
    return `${product.brand} ${product.modelName}`;
  }
  return product.brand ?? product.modelName ?? '-';
}

export function getServiceRecords(
  records: ServiceRecordJsonData[],
  included: any[]
): ServiceRecord[] {
  return records.map((record) => {
    const { attributes } = record;

    const performedTasks: PerformedTask[] = [];
    attributes.relationships.performedTasks.data.forEach((rel) => {
      const found = included.find(
        (item) => item.id === rel.id && item.type === rel.type
      );
      if (found) {
        performedTasks.push(found);
      }
    });

    return {
      ...record,
      attributes: {
        title: attributes.title,
        description: attributes.description,
        recordDate: attributes.recordDate,
        performedByType: attributes.performedByType,
        type: attributes.type,
        workshop: attributes.workshop,
        createdBy: attributes.createdBy,
        performedTasks
      }
    };
  });
}

export function getLanguageValuePair(
  pair: LanguageValuePair[],
  language = 'en'
): string | null {
  if (!pair.length) {
    return null;
  }
  const found = pair.find(
    (item) => item.language.toLowerCase() === language.toLowerCase()
  );
  return found?.value ?? pair[0].value;
}

export function getServicePlanGroupIntervals(
  list: ServiceInterval[]
): ServicePlanGroupIntervals[] {
  // Sort all intervals per sort group
  const groups: ServicePlanGroupIntervals[] = [];

  servicePlanSortGroups.forEach((group) => {
    const allParts: ServicePlanPart[] = [];

    const sortedList = [...list].sort((a, b) => a.sortNo - b.sortNo);
    const intervals: ServicePlanFlatInterval[] = sortedList.map((item) => {
      const parts: ServiceablePart[] = [];

      // Filter out subsystems belonging to this specific group
      const productSubSystems = item.productSubSystems.filter(
        (sub) => group.start <= sub.sortNo && sub.sortNo <= group.end
      );

      // Take out all the parts in the group
      productSubSystems.forEach(({ serviceableParts }) => {
        serviceableParts.forEach(({ name, genericName }) => {
          allParts.push({
            name: capitalizeFirstChar(getLanguageValuePair(name)),
            genericName
          });
        });
        parts.push(...serviceableParts);
      });

      const { id, name, rules } = item;
      const [{ occasion, repeat, unitName }] = rules;

      return {
        id,
        name: getLanguageValuePair(name),
        unit: getLanguageValuePair(unitName),
        occasion,
        repeat,
        parts
      };
    });

    // Check if group has parts
    const hasParts = intervals.some((item) => item.parts.length > 0);

    if (hasParts) {
      groups.push({
        title: group.title,
        allParts: filterDuplicates(allParts, 'genericName'),
        intervals: groupFlatIntervalsByTime(intervals)
      });
    }
  });

  return groups;
}

export function getServicePlanTitle({
  id,
  occasion,
  unit,
  repeat
}: ServicePlanFlatInterval): string {
  // Predelivery inspection
  if (id === 'PDI') {
    return 'Pre-delivery inspection';
  }

  const base = `${occasion} ${unit}`;
  return repeat ? `${base} (Every ${repeat} ${unit})` : base;
}

export function groupFlatIntervalsByTime(
  list: ServicePlanFlatInterval[]
): ServicePlanFlatInterval[] {
  let intervals: ServicePlanFlatInterval[] = [];
  list.forEach((item) => {
    const index = intervals.findIndex(
      (interval) =>
        interval.occasion === item.occasion &&
        interval.repeat === item.repeat &&
        interval.unit === item.unit
    );
    if (index !== -1) {
      intervals = updateArrayItem(intervals, index, {
        ...intervals[index],
        id: [intervals[index].id, item.id].join('-'),
        name: [intervals[index].name, item.name].join(', '),
        parts: [...intervals[index].parts, ...item.parts]
      });
    } else {
      intervals.push(item);
    }
  });

  return intervals;
}

export function getProductTreeItem(
  productTree: ProductTreeAttributes | null,
  iprId: string
): ProductTreeAttributes | null {
  if (productTree?.iprId === iprId) {
    return productTree;
  }

  const child = productTree?.children.find((child) => child.iprId === iprId);
  if (!child) {
    return null;
  }

  return child;
}

export function getDealerInfo(
  productRegistrationDealers: DealerAttributes[],
  warrantyAttributes?: WarrantyAttributes | null
): DealerInfo {
  const dealer = warrantyAttributes?.dealer ?? productRegistrationDealers[0];
  const dealerNumber = dealer?.customerNumber ?? warrantyAttributes?.customerNo;
  const companyCode = dealer?.companyCode ?? warrantyAttributes?.companyCode;
  const dealerId =
    companyCode && dealerNumber ? `${companyCode}-${dealerNumber}` : null;

  return {
    dealer,
    dealerId
  };
}

export function handleProductRegistrationAllowedErrors(
  productRegistrationResponse: CustomerProductRegistrationResponse,
  intl: IntlShape
): void {
  if (!productRegistrationResponse.errors) {
    showSuccessToast(
      intl.formatMessage({ id: 'modal.register_products_success' })
    );
    return;
  }

  showSuccessToast(
    intl.formatMessage({ id: 'modal.register_products_partial_success' })
  );

  const toastErrorCodes = {
    'warranty.condition.already.exist':
      'modal.register_products_warranty_condition_already_exists_warning',
    'relationship.exist': 'modal.register_products_relationship_warning',
    'invalid.serial': 'modal.register_products_invalid_serial_number_warning',
    default: 'modal.register_products_general_warning'
  };

  // Use set to avoid duplicate toasts
  const toastsToShow = new Set<string>();

  productRegistrationResponse.errors.forEach((e) => {
    if (e.code === 'warranty.condition.already.exist') {
      toastsToShow.add(toastErrorCodes[e.code]);
    }

    if (e.code.startsWith('relationship.') && e.code.includes('.exist')) {
      toastsToShow.add(toastErrorCodes['relationship.exist']);
    }

    if (e.code.includes('invalid.serial')) {
      toastsToShow.add(toastErrorCodes['invalid.serial']);
    }
  });

  // Add general message
  if (toastsToShow.size === 0) {
    toastsToShow.add(toastErrorCodes['default']);
  }

  // Show toasts
  toastsToShow.forEach((toastMessage) => {
    showWarningToast(intl.formatMessage({ id: toastMessage }), {
      autoClose: false
    });
  });
}
