import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  APIError,
  BatchJobState,
  BatchJob,
  BatchJobCreateBody,
  BatchJobFilter,
  BatchJobResponse
} from 'models';
import * as services from 'services';
import type { RootState } from 'store/store';

export const defaultState: BatchJobState = {
  list: {
    data: null,
    isLoading: false,
    error: null
  },
  details: {
    data: null,
    isLoading: false,
    error: null
  },
  create: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  remove: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  cancel: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  retry: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  start: {
    isLoading: false,
    isSuccess: false,
    error: null
  }
};

// Export slice
export const batchJobSlice = createSlice({
  name: 'batchJob',
  initialState: defaultState,
  reducers: {
    getBatchJobListInit: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    getBatchJobListSuccess: (state, action: PayloadAction<BatchJob[]>) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload;
    },
    getBatchJobListFailure: (state, action: PayloadAction<APIError>) => {
      state.list.data = null;
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    resetBatchJobList: (state) => {
      state.list = defaultState.list;
    },
    getBatchJobDetailsInit: (state) => {
      state.details.isLoading = true;
      state.details.error = null;
    },
    getBatchJobDetailsSuccess: (
      state,
      action: PayloadAction<BatchJobResponse>
    ) => {
      state.details.isLoading = false;
      state.details.error = null;
      state.details.data = action.payload;
    },
    getBatchJobDetailsFailure: (state, action: PayloadAction<APIError>) => {
      state.details.data = null;
      state.details.isLoading = false;
      state.details.error = action.payload;
    },
    resetBatchDetailsJob: (state) => {
      state.details = defaultState.details;
    },
    createBatchJobInit: (state) => {
      state.create.isLoading = true;
      state.create.error = null;
    },
    createBatchJobSuccess: (state) => {
      state.create.isLoading = false;
      state.create.isSuccess = true;
      state.create.error = null;
    },
    createBatchJobFailure: (state, action: PayloadAction<APIError>) => {
      state.create.isLoading = false;
      state.create.isSuccess = false;
      state.create.error = action.payload;
    },
    resetCreateBatchJob: (state) => {
      state.create = defaultState.create;
    },
    startBatchJobInit: (state) => {
      state.start.isLoading = true;
      state.start.isSuccess = false;
      state.start.error = null;
    },
    startBatchJobSuccess: (state) => {
      state.start.isLoading = false;
      state.start.isSuccess = true;
      state.start.error = null;
    },
    startBatchJobFailure: (state, action: PayloadAction<APIError>) => {
      state.start.isLoading = false;
      state.start.isSuccess = false;
      state.start.error = action.payload;
    },
    resetStartBatchJob: (state) => {
      state.start = defaultState.start;
    },
    cancelBatchJobInit: (state) => {
      state.cancel.isLoading = true;
      state.cancel.isSuccess = false;
      state.cancel.error = null;
    },
    cancelBatchJobSuccess: (state) => {
      state.cancel.isLoading = false;
      state.cancel.isSuccess = true;
      state.cancel.error = null;
    },
    cancelBatchJobFailure: (state, action: PayloadAction<APIError>) => {
      state.cancel.isLoading = false;
      state.cancel.isSuccess = false;
      state.cancel.error = action.payload;
    },
    resetCancelBatchJob: (state) => {
      state.cancel = defaultState.cancel;
    },
    retryBatchJobInit: (state) => {
      state.retry.isLoading = true;
      state.retry.isSuccess = false;
      state.retry.error = null;
    },
    retryBatchJobSuccess: (state) => {
      state.retry.isLoading = false;
      state.retry.isSuccess = true;
      state.retry.error = null;
    },
    retryBatchJobFailure: (state, action: PayloadAction<APIError>) => {
      state.retry.isLoading = false;
      state.retry.isSuccess = false;
      state.retry.error = action.payload;
    },
    resetRetryBatchJob: (state) => {
      state.retry = defaultState.retry;
    },
    removeBatchJobInit: (state) => {
      state.remove.isLoading = true;
      state.remove.isSuccess = false;
      state.remove.error = null;
    },
    removeBatchJobSuccess: (state) => {
      state.remove.isLoading = false;
      state.remove.isSuccess = true;
      state.remove.error = null;
    },
    removeBatchJobFailure: (state, action: PayloadAction<APIError>) => {
      state.remove.isLoading = false;
      state.remove.isSuccess = false;
      state.remove.error = action.payload;
    },
    resetRemoveBatchJob: (state) => {
      state.remove = defaultState.remove;
    }
  }
});

// Export selectors
export const batchJobListSelector = (state: RootState) => state.batchJob.list;
export const batchJobDetailsSelector = (state: RootState) =>
  state.batchJob.details;
export const createBatchJobSelector = (state: RootState) =>
  state.batchJob.create;
export const startBatchJobSelector = (state: RootState) => state.batchJob.start;
export const cancelBatchJobSelector = (state: RootState) =>
  state.batchJob.cancel;
export const retryBatchJobSelector = (state: RootState) => state.batchJob.retry;
export const removeBatchJobSelector = (state: RootState) =>
  state.batchJob.remove;

// Export actions
export const {
  getBatchJobListInit,
  getBatchJobListSuccess,
  getBatchJobListFailure,
  resetBatchJobList,
  getBatchJobDetailsInit,
  getBatchJobDetailsSuccess,
  getBatchJobDetailsFailure,
  resetBatchDetailsJob,
  createBatchJobInit,
  createBatchJobSuccess,
  createBatchJobFailure,
  resetCreateBatchJob,
  startBatchJobInit,
  startBatchJobSuccess,
  startBatchJobFailure,
  resetStartBatchJob,
  cancelBatchJobInit,
  cancelBatchJobSuccess,
  cancelBatchJobFailure,
  resetCancelBatchJob,
  retryBatchJobInit,
  retryBatchJobSuccess,
  retryBatchJobFailure,
  resetRetryBatchJob,
  removeBatchJobInit,
  removeBatchJobSuccess,
  removeBatchJobFailure,
  resetRemoveBatchJob
} = batchJobSlice.actions;

// Export reducer
export const batchJobReducer = batchJobSlice.reducer;

// Export thunks
export function fetchBatchJobList(filters: BatchJobFilter[] = []) {
  return async (dispatch: Dispatch) => {
    dispatch(getBatchJobListInit());
    try {
      const response = await services.getBatchJobList(filters);
      dispatch(getBatchJobListSuccess(response.included || []));
    } catch (e: any) {
      dispatch(getBatchJobListFailure(e));
    }
  };
}

export function fetchBatchJobById(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getBatchJobDetailsInit());
    try {
      const response = await services.getBatchJobById(id);
      dispatch(getBatchJobDetailsSuccess(response));
    } catch (e: any) {
      dispatch(getBatchJobDetailsFailure(e));
    }
  };
}

export function createBatchJob(body: BatchJobCreateBody) {
  return async (dispatch: Dispatch) => {
    dispatch(createBatchJobInit());
    try {
      await services.createBatchJob(body);
      dispatch(createBatchJobSuccess());
    } catch (e: any) {
      dispatch(createBatchJobFailure(e));
    }
  };
}

export function startBatchJob(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startBatchJobInit());
    try {
      await services.startBatchJob(id);
      dispatch(startBatchJobSuccess());
    } catch (e: any) {
      dispatch(startBatchJobFailure(e));
    }
  };
}

export function cancelBatchJob(id: string, reason?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(cancelBatchJobInit());
    try {
      await services.cancelBatchJob(id, reason);
      dispatch(cancelBatchJobSuccess());
    } catch (e: any) {
      dispatch(cancelBatchJobFailure(e));
    }
  };
}

export function retryBatchJob(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(retryBatchJobInit());
    try {
      await services.retryBatchJob(id);
      dispatch(retryBatchJobSuccess());
    } catch (e: any) {
      dispatch(retryBatchJobFailure(e));
    }
  };
}

export function removeBatchJob(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(removeBatchJobInit());
    try {
      await services.removeBatchJob(id);
      dispatch(removeBatchJobSuccess());
    } catch (e: any) {
      dispatch(removeBatchJobFailure(e));
    }
  };
}
