import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  removeWarrantyConditionByIprId,
  removeWarrantyConditionSelector,
  resetRemoveWarranty
} from 'store';
import { showSuccessToast } from 'utils/toast';
import Button from 'components/UI/Button';
import EditModal from 'components/UI/EditModal';
import Loader from 'components/UI/Loader';

import { ButtonGrid } from './styled';

type Props = {
  iprId: string;
  open: boolean;
  close: () => void;
  onRemove: () => void;
};

export const ConfirmRemoveWarrantyModal: FC<Props> = ({
  iprId,
  open,
  close,
  onRemove
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const removeWarranty = useAppSelector(removeWarrantyConditionSelector);

  // Remove
  const onRemoveWarranty = useCallback(() => {
    if (iprId) {
      dispatch(removeWarrantyConditionByIprId(iprId));
    }
  }, [dispatch, iprId]);

  // Success
  useEffect(() => {
    if (removeWarranty.isSuccess) {
      dispatch(resetRemoveWarranty());
      showSuccessToast(
        intl.formatMessage({ id: 'modal.confirm_remove_warranty_success' })
      );
      onRemove();
      close();
    }
  }, [close, dispatch, intl, onRemove, removeWarranty.isSuccess]);

  return (
    <EditModal
      open={open}
      title="modal.confirm_remove_warranty_title"
      close={close}
    >
      <p>
        <FormattedMessage id="modal.confirm_remove_warranty_text" />
      </p>
      {removeWarranty.isLoading && <Loader padding center />}
      <ButtonGrid>
        <Button
          onClick={close}
          backgroundColor="transparent"
          color="primary"
          marginRight
        >
          <FormattedMessage id="modal.btn_cancel" />
        </Button>
        <Button
          onClick={onRemoveWarranty}
          backgroundColor="danger"
          color="surface"
          disabled={removeWarranty.isLoading}
        >
          <FormattedMessage id="modal.btn_confirm" />
        </Button>
      </ButtonGrid>
    </EditModal>
  );
};
