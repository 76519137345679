import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.onSecondary};
  z-index: 99;
`;

export const TopBorder = styled.div`
  width: 100%;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const Content = styled.div`
  display: flex;
  height: 56px;
  border-bottom: 5px solid ${({ theme }) => theme.colors.background};
`;

export const PrimaryArea = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0 0 0 16px;
  background-color: ${({ theme }) => theme.colors.surface};

  @media (max-width: ${breakpoints.l}px) {
    align-items: center;
    padding: 8px 0 8px 16px;
  }
`;

export const SecondaryArea = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.surface};

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const SideArea = styled.div`
  display: flex;
  padding: 0 24px 0 8px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 0 8px;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;

  @media (max-width: ${breakpoints.l}px) {
    padding-top: 0;
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 8px 0;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;

  @media (max-width: ${breakpoints.l}px) {
    display: flex;
  }
`;

export const ActionMenu = styled.div`
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 64px;
  flex: 1;

  @media (max-width: ${breakpoints.xl}px) {
    justify-content: center;
    margin-left: 24px;
  }

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;
