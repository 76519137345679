import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { Consent, ConsentState } from 'models';
import { findCustomerConsents, findUserConsents } from 'services';
import type { RootState } from 'store/store';

export const defaultState: ConsentState = {
  data: [],
  isLoading: false,
  isError: false
};

// Export slice
export const consentSlice = createSlice({
  name: 'consent',
  initialState: defaultState,
  reducers: {
    getConsent: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getConsentSuccess: (state, action: PayloadAction<Consent[]>) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    },
    getConsentFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    resetConsentState: () => {
      return defaultState;
    }
  }
});

// Export selectors
export const consentSelector = (state: RootState) => state.consent;

// Export actions
export const {
  getConsent,
  getConsentSuccess,
  getConsentFailure,
  resetConsentState
} = consentSlice.actions;

// Export reducer
export const consentReducer = consentSlice.reducer;

// Export thunk
export function fetchCustomerConsent(customerId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getConsent());
    try {
      const consent = await findCustomerConsents(customerId);
      dispatch(getConsentSuccess(consent.data));
    } catch {
      dispatch(getConsentFailure());
    }
  };
}

export function fetchUserConsent(userId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getConsent());
    try {
      const consent = await findUserConsents(userId);
      dispatch(getConsentSuccess(consent.data));
    } catch {
      dispatch(getConsentFailure());
    }
  };
}
