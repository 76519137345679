import { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  sendServiceContractMail,
  resetSendServiceContractMail,
  serviceContractSendMailSelector
} from 'store';
import { showSuccessToast } from 'utils/toast';

import EditModal from 'components/UI/EditModal';
import Button from 'components/UI/Button';

import { ButtonGrid } from './styled';

type Props = {
  contractId: string;
  open: boolean;
  close: () => void;
};

const SendServiceContractEmailModal: FC<Props> = ({
  contractId,
  open,
  close
}) => {
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const { isSuccess, isLoading } = useAppSelector(
    serviceContractSendMailSelector
  );

  // Success
  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(
        intl.formatMessage({ id: 'contract_details.send_email_modal_success' })
      );
      dispatch(resetSendServiceContractMail());
      close();
    }
  }, [dispatch, close, isSuccess, intl]);

  // Send email
  const onClick = useCallback(() => {
    dispatch(sendServiceContractMail(contractId));
  }, [dispatch, contractId]);

  return (
    <EditModal
      title="contract_details.send_email_modal_title"
      open={open}
      close={close}
    >
      <div>
        <p>
          <FormattedMessage
            id="contract_details.send_email_modal_description"
            values={{ contractId }}
          />
        </p>
        <ButtonGrid>
          <Button
            marginRight
            onClick={close}
            backgroundColor="transparent"
            color="primary"
          >
            <FormattedMessage id="contract_details.send_email_modal_button_cancel" />
          </Button>
          <Button onClick={onClick} disabled={isLoading}>
            <FormattedMessage id="contract_details.send_email_modal_button_confirm" />
          </Button>
        </ButtonGrid>
      </div>
    </EditModal>
  );
};

export default SendServiceContractEmailModal;
